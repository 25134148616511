import React, { useEffect } from "react";
import portfoliobanner3leftimg  from '../images/laxmiraj-banner1.png'
import portfolibanner3rightimg1  from '../images/laxmiraj-banner2.png'
import portfolibanner3rightimg2  from '../images/laxmiraj-banner3.png'
import laxmirajicon  from '../images/laxmiraj_icon.png'
import imagessectionimg1  from '../images/laxmirajmokuf1.png'
import imagessectionimg2  from '../images/laxmirajmokuf2.png'
import imagessectionimg3  from '../images/laxmirajmokuf3.png'
import imagessectionimg4  from '../images/laxmirajmokuf4.png'
import imagessectionimg5  from '../images/laxmirajmokuf5.png'
import imagessectionimg6  from '../images/laxmirajmokuf6.png'
import scopeworkimg1 from '../images/laxmiraj-scop.png'
// import scopeworkimg2 from '../images/scope_work_img2.png'
import problemimg2 from '../images/laxmiraj-problem.png'
import { Link } from 'react-router-dom'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'
import { LaxmirajSolutionSlider } from "./component/LaxmirajSolutionSlider";

const LaxmirajDetail_backup = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        {/* ===portfolio_detail_banner_3-start=== */}
        <section className='portfolio_detail_banner_3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xxl-7 col-xl-6 col-lg-6'>
                        <div className='portfolio_detail_banner_3_left_image'>
                            <img src={portfoliobanner3leftimg} alt={portfoliobanner3leftimg} />
                            <div className='portfolio_detail_banner_3_left_content'>
                                <h6>Convenience Store</h6>
                                <h2>Laxmiraj Dry Fruits Marketplace</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-5 col-xl-6 col-lg-6'>
                        <div className='portfolio_detail_banner_3_right_image1'>
                            <img src={portfolibanner3rightimg1} alt={"portfolibanner3rightimg1"} />
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className='portfolio_detail_banner_3_right_content'>
                                    <img src={laxmirajicon} alt={"zumkhasmimg"} />
                                    <h4>Laxmiraj Dry Fruits delivers high-quality dry fruits, nuts, mouth fresheners, and spices with convenience and affordability across India.</h4>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className='portfolio_detail_banner_3_right_image2'>
                                    <img src={portfolibanner3rightimg2} alt={"portfolibanner3rightimg2"} />
                                    <Link href="">Get start with us Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_3-end=== */}

        {/* ===portfolio_detail_banner_3_mission_challenge-start=== */}
        <section className='portfolio_detail_3_mission_challenge p-100'>
            <div className="container">
                <div className="row">
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Mission</h3>
                            <p>Laxmiraj Dry Fruits aims to offer a seamless online shopping experience with high-quality dry fruits, nuts, mouth fresheners, and spices at affordable prices. We strive to enrich lives through exceptional products, ensuring convenience and building long-lasting relationships with customers across India.</p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Business Problem</h3>
                            <p>Laxmiraj Dry Fruits faces challenges such as a lack of user-friendly design, slow loading speeds, and poor mobile optimization. Limited online visibility and ineffective digital marketing strategies hinder customer reach, affecting sales and growth potential in a competitive market.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mt-1 mt-sm-2'>
                            <h3>CMExpertise Solution</h3>
                            <p>To address Unibest's challenges, we developed a comprehensive e-commerce platform tailored to their needs. This solution included a user-friendly interface, streamlined navigation, and robust backend support to enhance operational efficiency. Our focus on scalability ensures Unibest can grow and adapt in a competitive digital marketplace seamlessly.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_3_mission_challenge-end=== */}

        {/* ===portfolio_detail_3_images_section=== */}
        <section className='portfolio_detail_3_images_section'>
            <div className='imagessectionwrp-1'>
                <img src={imagessectionimg1} alt={imagessectionimg1} className='imagessectionimg1' />
                <img src={imagessectionimg4} alt={imagessectionimg4} className='imagessectionimg4' />
            </div>
            <div className='imagessectionwrp-2'>
                <div className='imagessectionwrp-2_top'>
                    <img src={imagessectionimg2} alt={imagessectionimg2} className='imagessectionimg2' />
                    <img src={imagessectionimg3} alt={imagessectionimg3} className='imagessectionimg3' />
                </div>
                <div className='imagessectionwrp-2_bottom'>
                    <img src={imagessectionimg5} alt={imagessectionimg5} className='imagessectionimg5' />
                    <img src={imagessectionimg6} alt={imagessectionimg6} className='imagessectionimg6' />
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_3_images_section=== */}

        {/* ===portfolio_detail_2_scope_work-start=== */}
        <section className='portfolio_detail_2_scope_work p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Design Thinking Process</h3>
                            <p>The Design Thinking Process is an iterative approach to problem-solving, involving five stages: Empathize to understand users, Define the problem, Ideate solutions, Prototype concepts, and Test to refine ideas. This methodology encourages creativity and collaboration, ensuring solutions meet user needs and drive innovation.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            {/* <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_scope_work-ebd=== */}

        {/* ===portfolio_detail_3_problem_start=== */}
        <section className='portfolio_detail_3_problem'>
            <div className='row'>
                <div className='col-lg-6 '>
                    <div className='portfolio_detail_3_problem_image'>
                        <img src={problemimg2} alt={problemimg2} />
                    </div>
                </div>
                <div className='col-lg-6 align-self-center'>
                    <div className='mission-challange-research-wrp'>
                        <h3>Results and Impact</h3>
                        <p>The Design Thinking Process led to transformative outcomes, reducing development time by 25% and improving product usability. It boosted client satisfaction and fostered a culture of collaboration and creativity, driving long-term success within our teams and enhancing overall project outcomes.</p>
                        <p>The Design Thinking Process resulted in a 25% faster development cycle, improved usability, and higher client satisfaction. This user-centered approach encouraged collaboration, driving innovation, and fostering a creative culture that enhanced long-term project success.</p>
                        <ul>
                            <li><span><i className="fa-solid fa-check"></i></span>The Design Thinking Process accelerated product development by 25%, enhancing usability and customer satisfaction.</li>
                            <li><span><i className="fa-solid fa-check"></i></span>By focusing on user-centric solutions, we cultivated innovation, creativity, and collaboration, improving overall outcomes.</li>
                            <li><span><i className="fa-solid fa-check"></i></span>This approach not only streamlined workflows but also strengthened client relationships, leading to sustainable growth and success.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_3_problem_start=== */}

        {/* ===portfolio_detail_2_solution-start=== */}
        <section className='portfolio_detail_2_solution p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-sm-5 mb-3'>
                            <h3>Future Enhancements</h3>
                            <p>In the coming phases, we will focus on optimizing our platform's scalability to accommodate growing user demands. We plan to implement advanced security measures to protect user data further and enhance the interface for improved accessibility. Additionally, integrating automated customer support will streamline user interactions and improve overall satisfaction.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='col-12'>
                <LaxmirajSolutionSlider/>
            </div>
        </section>
        {/* ===portfolio_detail_2_solution-end=== */}

        <Testimonial />
        <LocationSlider />
    </>
  )
}

export default LaxmirajDetail_backup
