import React, { useEffect } from "react";
// import { Link } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import portfoliobannerextraimgright from "../images/portfoliobanner-extra-img-right.png";
import portfoliobannerextraimgleft from "../images/portfoliobanner-extra-img-left.png";
import portfoliobannerinfoimg1 from "../images/portfolio-banner-info-img1.png";
import portfoliobannerinfoimg2 from "../images/portfolio-banner-info-img2.png";
import portfoliobannerinfoimg3 from "../images/portfolio-banner-info-img3.png";
import portfoliobannerinfoimg4 from "../images/portfolio-banner-info-img4.png";
// import porfoliothemeimg1 from "../images/porfolio-theme-img1.png";
// import porfoliothemeimg2 from "../images/porfolio-theme-img2.png";
// import porfoliothemeimg3 from "../images/porfolio-theme-img3.png";
// import porfoliothemeimg4 from "../images/porfolio-theme-img4.png";
// import porfoliothemeimg5 from "../images/porfolio-theme-img5.png";
// import porfoliothemeimg6 from "../images/porfolio-theme-img6.png";
import jewellerystoreimg from "../images/jewellery-store.jpg";
import kishoricreationimg from "../images/kishoricreation.jpg";
import interiordesigningimg from "../images/interiordesigning.jpg";
import laxmirajdryfruitsimg from "../images/laxmirajdryfruits.jpg";
import unibestimg from "../images/unibest.jpg";
import yashindustriesimg from "../images/yashindustries.jpg";
// import porfoliothemeimg7 from '../images/porfolio-theme-img7.png'
// import porfoliothemeimg8 from '../images/porfolio-theme-img8.png'
// import porfoliothemeimg9 from '../images/porfolio-theme-img9.png'
// import porfoliothemeimg10 from '../images/porfolio-theme-img10.png'
// import porfoliothemeimg11 from '../images/porfolio-theme-img11.png'
// import porfoliothemeimg12 from '../images/porfolio-theme-img12.png'
import { MdOutlineArrowOutward } from "react-icons/md";
import LocationSlider from "./LocationSlider";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- ===Banner-Start=== --> */}
      <section className="banner_section portfolio_banner">
        <img
          src={portfoliobannerextraimgleft}
          alt=""
          className="banner-extra-img-left"
        />
        <img
          src={portfoliobannerextraimgright}
          alt=""
          className="banner-extra-img-right"
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner_main_wrp">
                <div className="banner_content">
                  <h1>
                    Showcasing Our Successful Projects That Drive Business
                    Growth
                  </h1>
                  <h4>
                    Discover how our diverse projects have empowered businesses
                    to grow, innovate, and achieve their goals with success.
                  </h4>
                  <div
                    className="banner_btn"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    <button className="cmn-btn">
                      <span className="cmn-btn-text1">
                        Sign Up <MdOutlineArrowOutward className="button-icon" />
                      </span>
                      <span className="cmn-btn-text2">
                        Sign Up <MdOutlineArrowOutward className="button-icon" />
                      </span>
                    </button>
                  </div>
                  <p>
                    Launch your <span>e-commerce</span> store today
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="portfolio_banner_info">
            <div className="portfolio_banner_info_images portfolio_banner_info_images_1">
              <img src={portfoliobannerinfoimg1} alt="" />
            </div>
            <div className="portfolio_banner_info_images portfolio_banner_info_images_2">
              <img src={portfoliobannerinfoimg2} alt="" />
            </div>
            <div className="portfolio_banner_info_images portfolio_banner_slider">
              <h4>Ready to launch Application for both Platform</h4>
            </div>
            <div className="portfolio_banner_info_images portfolio_banner_info_images_3">
              <img src={portfoliobannerinfoimg3} alt="" />
            </div>
            <div className="portfolio_banner_info_images portfolio_banner_info_images_4">
              <img src={portfoliobannerinfoimg4} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio_info_wrp p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Explore Our Creative Portfolio</h2>
                <div>
                  <p>
                    Discover the innovative solutions we've crafted for various
                    clients. Our portfolio showcases projects that highlight our
                    expertise.
                  </p>
                  <a href="/contact">
                    Contact Us <MdOutlineArrowOutward className="button-icon" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6 mb-lg-5 mb-4">
              <div className="portfolio_info_card">
                <Link to="/zumkha-detail">
                  <div className="portfolio_info_wrp_img">
                    <img src={jewellerystoreimg} alt={jewellerystoreimg} />
                  </div>
                  <div className="portfolio_info_wrp_content">
                    <h3>Zumkha</h3>
                    <MdOutlineArrowOutward className="button-icon" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6 mb-lg-5 mb-4">
              <div className="portfolio_info_card">
                <Link to="/kishori-detail">
                  <div className="portfolio_info_wrp_img">
                    <img src={kishoricreationimg} alt={kishoricreationimg} />
                  </div>
                  <div className="portfolio_info_wrp_content">
                    <h3>Kishoricreation</h3>
                    <MdOutlineArrowOutward className="button-icon" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6 mb-lg-5 mb-4">
              <div className="portfolio_info_card">
                <Link to="/Unibest-Detail">
                  <div className="portfolio_info_wrp_img">
                    <img src={unibestimg} alt={unibestimg} />
                  </div>
                  <div className="portfolio_info_wrp_content">
                    <h3>Unibest</h3>
                    <MdOutlineArrowOutward className="button-icon" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6 mb-lg-5 mb-4">
              <div className="portfolio_info_card">
                <Link to="/dpdecor-detail">
                  <div className="portfolio_info_wrp_img">
                    <img src={interiordesigningimg} alt={interiordesigningimg} />
                  </div>
                  <div className="portfolio_info_wrp_content">
                    <h3>DP Decor</h3>
                    <MdOutlineArrowOutward className="button-icon" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6 mb-lg-5 mb-4">
              <div className="portfolio_info_card">
                <Link to="/YashIndustries_Detail">
                  <div className="portfolio_info_wrp_img">
                    <img src={yashindustriesimg} alt={yashindustriesimg} />
                  </div>
                  <div className="portfolio_info_wrp_content">
                    <h3>Yash Industries</h3>
                    <MdOutlineArrowOutward className="button-icon" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-6 mb-lg-5 mb-4">
              <div className="portfolio_info_card">
                <Link to="/laxmiraj-detail">
                  <div className="portfolio_info_wrp_img">
                    <img src={laxmirajdryfruitsimg} alt={laxmirajdryfruitsimg} />
                  </div>
                  <div className="portfolio_info_wrp_content">
                    <h3>Laxmiraj</h3>
                    <MdOutlineArrowOutward className="button-icon" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===Banner-end=== --> */}

      {/* <section className='portfolio_info_wrp p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-sm-6 col-6 mb-lg-5 mb-4'>
                        <div className='portfolio_info_card'>
                            <div className='portfolio_info_wrp_img'>
                                <img src={porfoliothemeimg1} alt={porfoliothemeimg1} />
                            </div>
                            <div className='portfolio_info_wrp_content'>
                                <h3>Grocery Store</h3>
                                <MdOutlineArrowOutward  className="button-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-lg-5 mb-4'>
                        <div className='portfolio_info_card'>
                            <div className='portfolio_info_wrp_img'>
                                <img src={porfoliothemeimg1} alt={porfoliothemeimg1} />
                            </div>
                            <div className='portfolio_info_wrp_content'>
                                <h3>Super Market</h3>
                                <MdOutlineArrowOutward  className="button-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-lg-5 mb-4'>
                        <div className='portfolio_info_card'>
                            <div className='portfolio_info_wrp_img'>
                                <img src={porfoliothemeimg1} alt={porfoliothemeimg1} />
                            </div>
                            <div className='portfolio_info_wrp_content'>
                                <h3>Storage</h3>
                                <MdOutlineArrowOutward  className="button-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-lg-5 mb-4'>
                        <div className='portfolio_info_card'>
                            <div className='portfolio_info_wrp_img'>
                                <img src={porfoliothemeimg1} alt={porfoliothemeimg1} />
                            </div>
                            <div className='portfolio_info_wrp_content'>
                                <h3>Tea Shop</h3>
                                <MdOutlineArrowOutward  className="button-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-lg-5 mb-4'>
                        <div className='portfolio_info_card'>
                            <div className='portfolio_info_wrp_img'>
                                <img src={porfoliothemeimg1} alt={porfoliothemeimg1} />
                            </div>
                            <div className='portfolio_info_wrp_content'>
                                <h3>Gift Shop</h3>
                                <MdOutlineArrowOutward  className="button-icon"/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-lg-5 mb-4'>
                        <div className='portfolio_info_card'>
                            <div className='portfolio_info_wrp_img'>
                                <img src={porfoliothemeimg1} alt={porfoliothemeimg1} />
                            </div>
                            <div className='portfolio_info_wrp_content'>
                                <h3>Footwear Store</h3>
                                <MdOutlineArrowOutward  className="button-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

      <LocationSlider />
    </>
  );
};

export default Portfolio;
