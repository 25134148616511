import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Slider from "react-slick";
import bannerextraimgleft from "../images/banner-extra-img-left.png";
import bannerextraimgright from "../images/banner-extra-img-right.png";
import bannerthemeimg1 from "../images/banner-img-wrp-img-1.png";
import bannerthemeimg2 from "../images/banner-img-wrp-img-2.png";
import bannerthemeimg3 from "../images/banner-img-wrp-img-3.png";
import bannerthemeimg4 from "../images/banner-img-wrp-img-4.png";
import bannerthemeimg5 from "../images/banner-img-wrp-img-5.png";
import bannerthemeimg6 from "../images/banner-img-wrp-img-6.png";
import weprovideimg from "../images/we_provide_img.png";
import buildwebsiteimg from "../images/build_website_img.png";
import buildappimg from "../images/build-app-img.png";
import profileimg from "../images/profile-img.png";
import websitetempletsimg1 from "../images/website-templets-img-1.png";
import websitetempletsimg2 from "../images/website-templets-img-2.png";
import ourproejctsimg1 from "../images/our-proejcts-img1.png";
import ourproejctsimg2 from "../images/our-proejcts-img2.png";
import ourproejctsimg3 from "../images/our-proejcts-img3.png";
import ourproejctsimg4 from "../images/our-proejcts-img4.png";
import ourproejctsimg5 from "../images/our-proejcts-img5.png";
import ourproejctsimg6 from "../images/our-proejcts-img6.png";
// import insightsimg1 from "../images/insights-img-1.png";
// import insightsimg2 from "../images/insights-img-2.png";
// import insightsimg3 from "../images/insights-img-3.png";
// import websitestepimg1 from "../images/website-setp-img-1.png";

import jewellerystoreimg from "../images/jewellery-store.jpg";
import kishoricreationimg from "../images/kishoricreation.jpg";
import interiordesigningimg from "../images/interiordesigning.jpg";
import laxmirajdryfruitsimg from "../images/laxmirajdryfruits.jpg";
import unibestimg from "../images/unibest.jpg";
import yashindustriesimg from "../images/yashindustries.jpg";







import { MdOutlineArrowOutward } from "react-icons/md";

import LocationSlider from "./LocationSlider";
import { WebsiteStep } from "./component/WebsiteStep";
import { BrandSlider } from "./component/BrandSlider";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <!-- ===Banner-Start=== --> */}
      <section className="banner_section">
        <img
          src={bannerextraimgleft}
          alt=""
          className="banner-extra-img-left"
        />
        <img
          src={bannerextraimgright}
          alt=""
          className="banner-extra-img-right"
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner_main_wrp">
                <div className="banner_content">
                  <h1>
                    <span>LaunchEstore</span> <br /> Start Selling Online in 30
                    Seconds!
                  </h1>
                  <h4>
                    Instantly launch your online store and start selling. Fast
                    setup, powerful tools, and everything you need to grow your
                    business.
                  </h4>
                  <div className="banner_btn">
                    <button
                      className="cmn-btn"
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      <span className="cmn-btn-text1">
                        Create Your Store{" "}
                        <MdOutlineArrowOutward className="button-icon" />
                      </span>

                      <span className="cmn-btn-text2">
                        Create Your Store{" "}
                        <MdOutlineArrowOutward className="button-icon" />
                      </span>
                    </button>
                    <button
                      className="cmn-btn play-reel-btn"
                      onClick={() => {
                        navigate("/contact");
                      }}
                    >
                      <span className="cmn-btn-text1">Book a Free Demo</span>
                      <span className="cmn-btn-text2">Book a Free Demo</span>
                    </button>
                  </div>
                  <p>
                    Launch your <span>e-commerce</span> store today
                  </p>
                </div>

                <div className="banner-image-wrp">
                  <img
                    src={bannerthemeimg1}
                    alt=""
                    className="banner-img-wrp-img-1"
                  />
                  <img
                    src={bannerthemeimg2}
                    alt=""
                    className="banner-img-wrp-img-2"
                  />
                  <img
                    src={bannerthemeimg3}
                    alt=""
                    className="banner-img-wrp-img-3"
                  />
                  <img
                    src={bannerthemeimg4}
                    alt=""
                    className="banner-img-wrp-img-4"
                  />
                  <img
                    src={bannerthemeimg5}
                    alt=""
                    className="banner-img-wrp-img-5"
                  />
                  <img
                    src={bannerthemeimg6}
                    alt=""
                    className="banner-img-wrp-img-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          {/* <div className="banner-brand-slider">
            <div className="owl-carousel owl-theme brand-slider">
              <Slider {...settings}>
                <div className="banner-brand-slider-img">
                  <img src={brandimg1} alt="brand-img" />
                </div>
                <div className="banner-brand-slider-img">
                  <img src={brandimg2} alt="brand-img" />
                </div>
                <div className="banner-brand-slider-img">
                  <img src={brandimg3} alt="brand-img" />
                </div>
                <div className="banner-brand-slider-img">
                  <img src={brandimg4} alt="brand-img" />
                </div>
                <div className="banner-brand-slider-img">
                  <img src={brandimg5} alt="brand-img" />
                </div>
                <div className="banner-brand-slider-img">
                  <img src={brandimg6} alt="brand-img" />
                </div>
                <div className="banner-brand-slider-img">
                  <img src={brandimg7} alt="brand-img" />
                </div>
              </Slider>
            </div>
          </div> */}
          <BrandSlider />
        </div>
      </section>
      {/* <!-- ===Banner-end=== --> */}

      {/* <!-- ===Content-Start=== --> */}
      <section className="Content_section p-100">
        <div className="container">
          <div className="Content_wrapper">
            <h2>
              Launch your online store on the best eCommerce platform. With
              powerful features and seamless integration, easily grow your
              business and start selling today!
            </h2>
          </div>
        </div>
      </section>
      {/* <!-- ===Content-end=== --> */}

      {/* <!-- ===We-Provide-start=== --> */}
      <section className="we_provide p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="we_provide_title">
                <h2>Launch Your, Successful Store</h2>
                <button
                  className="cmn-btn"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  <span className="cmn-btn-text1">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                  <span className="cmn-btn-text2">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="we_provide_image-wrp">
                <img src={weprovideimg} alt="What_we_provide" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content1">
                <h3>Create Your Online Shop</h3>
                <p>
                  Set up your store quickly with user-friendly tools that
                  simplify online selling.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content2">
                <h3>Choose a Store Theme</h3>
                <p>
                  Discover a variety of stylish premium themes to enhance your
                  store's look.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content3">
                <h3>Customize Your Brand Identity</h3>
                <p>
                  Personalize your store with easy design options that reflect
                  your unique brand style.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===We-Provide-end=== --> */}

      {/* <!-- ===Build-Web-App-start=== --> */}
      <section className="build_web_app p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Make Your Products Shine in Your Store</h2>
                <div>
                  <p>
                    Save time and money with LaunchEstore’s all-in-one platform.
                    Build your site, sell products, and engage clients to grow
                    your online business.
                  </p>
                  <button
                    className="cmn-btn"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    <span className="cmn-btn-text1">
                      Sign Up <MdOutlineArrowOutward className="button-icon" />
                    </span>
                    <span className="cmn-btn-text2">
                      Sign Up <MdOutlineArrowOutward className="button-icon" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="build_web_app-inner">
          <div className="row">
            <div className="col-lg-6">
              <div className="build_web_app-image-wrp build-web-image-wrp">
                <img src={buildwebsiteimg} alt="bulid_website" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="build_web_app-content-wrp">
                <h3>Scalable Infrastructure</h3>
                <p>
                  Our infrastructure is designed to support your business growth
                  and scalability effortlessly.
                </p>
                <ul>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1066)">
                          <path
                            d="M7.71478 6.79039C7.71478 7.75929 6.85098 8.54539 5.78632 8.54539H1.92942C0.86436 8.54539 0.000976562 7.75929 0.000976562 6.79039V3.28039C0.000976562 2.31112 0.86436 1.52539 1.92942 1.52539H5.78632C6.85098 1.52539 7.71478 2.31112 7.71478 3.28039V6.79039ZM7.71478 16.1504C7.71478 17.1193 6.85098 17.9054 5.78632 17.9054H1.92942C0.86436 17.9054 0.000976562 17.1193 0.000976562 16.1504V12.6404C0.000976562 11.6715 0.86436 10.8854 1.92942 10.8854H5.78632C6.85098 10.8854 7.71478 11.6715 7.71478 12.6404V16.1504ZM10.286 3.28039C10.286 2.31112 11.1498 1.52539 12.2145 1.52539H16.0714C17.136 1.52539 17.9998 2.31112 17.9998 3.28039V6.79039C17.9998 7.75929 17.136 8.54539 16.0714 8.54539H12.2145C11.1498 8.54539 10.286 7.75929 10.286 6.79039V3.28039ZM17.9998 16.1504C17.9998 17.1193 17.136 17.9054 16.0714 17.9054H12.2145C11.1498 17.9054 10.286 17.1193 10.286 16.1504V12.6404C10.286 11.6715 11.1498 10.8854 12.2145 10.8854H16.0714C17.136 10.8854 17.9998 11.6715 17.9998 12.6404V16.1504Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1066">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.355469)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Scalable Architecture, Easily adjust resources as your
                    business grows.
                  </li>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1071)">
                          <path
                            d="M8.17425 1.07897C8.69809 0.845747 9.30271 0.845747 9.82655 1.07897L17.5112 4.49922C17.81 4.63125 17.9998 4.91901 17.9998 5.20677C17.9998 5.55209 17.81 5.83984 17.5112 5.97526L9.82655 9.39455C9.30271 9.62812 8.69809 9.62812 8.17425 9.39455L0.490672 5.97526C0.192144 5.83984 0.000976562 5.55209 0.000976562 5.20677C0.000976562 4.91901 0.192109 4.63125 0.490672 4.49922L8.17425 1.07897ZM17.5112 8.83259C17.81 8.96456 17.9998 9.25235 17.9998 9.54013C17.9998 9.88544 17.81 10.1732 17.5112 10.3086L9.82655 13.7279C9.30271 13.9614 8.69809 13.9614 8.17425 13.7279L0.490672 10.3086C0.192144 10.1732 0.000976562 9.88544 0.000976562 9.54013C0.000976562 9.25235 0.192109 8.96456 0.490672 8.83259L2.36086 7.99974L7.70319 10.3763C8.52581 10.7453 9.47499 10.7453 10.2976 10.3763L15.641 7.99974L17.5112 8.83259ZM10.2976 14.7097L15.641 12.3331L17.5112 13.1659C17.81 13.2979 17.9998 13.5857 17.9998 13.8735C17.9998 14.2188 17.81 14.5065 17.5112 14.6419L9.82655 18.0612C9.30271 18.2948 8.69809 18.2948 8.17425 18.0612L0.490672 14.6419C0.192144 14.5065 0.000976562 14.2188 0.000976562 13.8735C0.000976562 13.5857 0.192109 13.2979 0.490672 13.1659L2.36086 12.3331L7.70319 14.7097C8.52581 15.0787 9.47499 15.0787 10.2976 14.7097Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1071">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.544678)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Robust Security,Protect your data with advanced security
                    protocols.
                  </li>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1077)">
                          <path
                            d="M9.0004 19.3453C4.02962 19.3453 0.000976562 15.1775 0.000976562 10.0349C0.000976562 4.89244 4.02962 0.724609 9.0004 0.724609C13.9712 0.724609 17.9998 4.89244 17.9998 10.0349C17.9998 15.1775 13.9712 19.3453 9.0004 19.3453ZM8.15669 10.0349C8.15669 10.3259 8.29729 10.5987 8.53286 10.7296L11.9076 13.0572C12.2943 13.3554 12.8181 13.2463 13.0466 12.8462C13.3349 12.4462 13.2294 11.9043 12.8427 11.6352L9.84411 9.56945V5.08883C9.84411 4.60513 9.46794 4.21599 8.96879 4.21599C8.53286 4.21599 8.12508 4.60513 8.12508 5.08883L8.15669 10.0349Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1077">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.724609)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    High Availability,Ensure your services are always online and
                    accessible.
                  </li>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1084)">
                          <path
                            d="M14.7633 2.06836C16.0347 2.06836 17.0685 3.1004 17.0685 4.37378V15.9009C17.0685 17.1725 16.0347 18.2063 14.7633 18.2063H3.23691C1.96361 18.2063 0.931641 17.1725 0.931641 15.9009V4.37378C0.931641 3.1004 1.96361 2.06836 3.23691 2.06836H14.7633ZM9.74929 5.72822C9.74929 5.29955 9.3999 4.95014 8.9713 4.95014C8.54264 4.95014 8.19324 5.29955 8.19324 5.72822V6.30817C7.97352 6.3586 7.76103 6.39462 7.56287 6.51349C6.91454 6.79087 6.3022 7.31319 6.15812 8.0985C6.00684 8.93057 6.36704 9.67982 7.06943 10.1301C7.58088 10.4579 8.25805 10.6489 8.80199 10.8037C8.88119 10.8254 8.93167 10.847 9.04689 10.8722C9.47555 10.9874 9.92583 11.0775 10.2896 11.3585C10.7111 11.6466 10.5093 12.1582 10.0771 12.3022C9.72412 12.4644 9.26661 12.5184 8.65431 12.4284C8.23649 12.3635 7.81861 12.2194 7.41164 12.0825C7.32156 12.0537 7.23151 12.0213 7.14146 11.9925C6.73444 11.8556 6.29139 12.0789 6.15812 12.4859C6.02125 12.893 6.24457 13.3361 6.6516 13.4694C7.15948 13.6351 7.67093 13.8044 8.19324 13.9196V14.5464C8.19324 14.9751 8.54264 15.3245 8.9713 15.3245C9.3999 15.3245 9.74929 14.9751 9.74929 14.5464V13.9989C10.0699 13.9629 10.376 13.8909 10.657 13.7756C11.3342 13.5054 11.8925 12.9759 12.0438 12.169C12.1951 11.3332 11.8673 10.5516 11.1685 10.0725C10.6318 9.70506 9.91499 9.50334 9.3531 9.34125C9.27022 9.31962 9.18379 9.29438 9.10091 9.24035C8.69033 9.16115 8.26166 9.04586 7.9051 8.81889C7.45482 8.53074 7.78259 8.11289 8.17885 7.93996C8.62545 7.74908 9.17656 7.70583 9.65202 7.79588C9.90776 7.84629 10.1599 7.91833 10.4121 7.98682C10.4661 8.00483 10.5237 8.01923 10.5778 8.03362C10.992 8.14891 11.4206 7.90755 11.5359 7.49333C11.6511 7.07905 11.4062 6.65038 10.992 6.50269C10.9272 6.5171 10.8587 6.49548 10.7939 6.47747C10.4481 6.38021 10.0987 6.28295 9.74929 6.23973V5.72822Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1084">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.916016)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Seamless Integration,Connect with various tools and
                    platforms effortlessly.
                  </li>
                </ul>
                <button
                  className="cmn-btn"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  <span className="cmn-btn-text1">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                  <span className="cmn-btn-text2">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                </button>

                <div className="build_web_app-content-profile d-none">
                  <p>
                    “LaunchEstore transformed my online business! Setting up my
                    store was so simple, and their customer support is always
                    there to help. Thanks to their tools, I reached new
                    customers and saw sales skyrocket. Highly recommended!”
                  </p>
                  <div className="build_web_app-profile-inner d-none">
                    <div className="build_web_app-profile-img">
                      <img src={profileimg} alt="" />
                    </div>
                    <div className="build_web_app-profile-content">
                      <h4>Priya Mehta</h4>
                      <p>Jwellery Owner</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="build_web_app-inner build-app-wrap">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="build_web_app-content-wrp">
                <h3>Create Online Business</h3>
                <p>
                  Create a unique store and access powerful tools to grow your
                  business seamlessly.
                </p>
                <ul>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1066)">
                          <path
                            d="M7.71478 6.79039C7.71478 7.75929 6.85098 8.54539 5.78632 8.54539H1.92942C0.86436 8.54539 0.000976562 7.75929 0.000976562 6.79039V3.28039C0.000976562 2.31112 0.86436 1.52539 1.92942 1.52539H5.78632C6.85098 1.52539 7.71478 2.31112 7.71478 3.28039V6.79039ZM7.71478 16.1504C7.71478 17.1193 6.85098 17.9054 5.78632 17.9054H1.92942C0.86436 17.9054 0.000976562 17.1193 0.000976562 16.1504V12.6404C0.000976562 11.6715 0.86436 10.8854 1.92942 10.8854H5.78632C6.85098 10.8854 7.71478 11.6715 7.71478 12.6404V16.1504ZM10.286 3.28039C10.286 2.31112 11.1498 1.52539 12.2145 1.52539H16.0714C17.136 1.52539 17.9998 2.31112 17.9998 3.28039V6.79039C17.9998 7.75929 17.136 8.54539 16.0714 8.54539H12.2145C11.1498 8.54539 10.286 7.75929 10.286 6.79039V3.28039ZM17.9998 16.1504C17.9998 17.1193 17.136 17.9054 16.0714 17.9054H12.2145C11.1498 17.9054 10.286 17.1193 10.286 16.1504V12.6404C10.286 11.6715 11.1498 10.8854 12.2145 10.8854H16.0714C17.136 10.8854 17.9998 11.6715 17.9998 12.6404V16.1504Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1066">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.355469)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Sell products, services, and digital downloads.
                  </li>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1071)">
                          <path
                            d="M8.17425 1.07897C8.69809 0.845747 9.30271 0.845747 9.82655 1.07897L17.5112 4.49922C17.81 4.63125 17.9998 4.91901 17.9998 5.20677C17.9998 5.55209 17.81 5.83984 17.5112 5.97526L9.82655 9.39455C9.30271 9.62812 8.69809 9.62812 8.17425 9.39455L0.490672 5.97526C0.192144 5.83984 0.000976562 5.55209 0.000976562 5.20677C0.000976562 4.91901 0.192109 4.63125 0.490672 4.49922L8.17425 1.07897ZM17.5112 8.83259C17.81 8.96456 17.9998 9.25235 17.9998 9.54013C17.9998 9.88544 17.81 10.1732 17.5112 10.3086L9.82655 13.7279C9.30271 13.9614 8.69809 13.9614 8.17425 13.7279L0.490672 10.3086C0.192144 10.1732 0.000976562 9.88544 0.000976562 9.54013C0.000976562 9.25235 0.192109 8.96456 0.490672 8.83259L2.36086 7.99974L7.70319 10.3763C8.52581 10.7453 9.47499 10.7453 10.2976 10.3763L15.641 7.99974L17.5112 8.83259ZM10.2976 14.7097L15.641 12.3331L17.5112 13.1659C17.81 13.2979 17.9998 13.5857 17.9998 13.8735C17.9998 14.2188 17.81 14.5065 17.5112 14.6419L9.82655 18.0612C9.30271 18.2948 8.69809 18.2948 8.17425 18.0612L0.490672 14.6419C0.192144 14.5065 0.000976562 14.2188 0.000976562 13.8735C0.000976562 13.5857 0.192109 13.2979 0.490672 13.1659L2.36086 12.3331L7.70319 14.7097C8.52581 15.0787 9.47499 15.0787 10.2976 14.7097Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1071">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.544678)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Receive payments instantly, no commissions.
                  </li>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1077)">
                          <path
                            d="M9.0004 19.3453C4.02962 19.3453 0.000976562 15.1775 0.000976562 10.0349C0.000976562 4.89244 4.02962 0.724609 9.0004 0.724609C13.9712 0.724609 17.9998 4.89244 17.9998 10.0349C17.9998 15.1775 13.9712 19.3453 9.0004 19.3453ZM8.15669 10.0349C8.15669 10.3259 8.29729 10.5987 8.53286 10.7296L11.9076 13.0572C12.2943 13.3554 12.8181 13.2463 13.0466 12.8462C13.3349 12.4462 13.2294 11.9043 12.8427 11.6352L9.84411 9.56945V5.08883C9.84411 4.60513 9.46794 4.21599 8.96879 4.21599C8.53286 4.21599 8.12508 4.60513 8.12508 5.08883L8.15669 10.0349Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1077">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.724609)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Effortlessly manage inventory, shipping, and taxes.
                  </li>
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_941_1084)">
                          <path
                            d="M14.7633 2.06836C16.0347 2.06836 17.0685 3.1004 17.0685 4.37378V15.9009C17.0685 17.1725 16.0347 18.2063 14.7633 18.2063H3.23691C1.96361 18.2063 0.931641 17.1725 0.931641 15.9009V4.37378C0.931641 3.1004 1.96361 2.06836 3.23691 2.06836H14.7633ZM9.74929 5.72822C9.74929 5.29955 9.3999 4.95014 8.9713 4.95014C8.54264 4.95014 8.19324 5.29955 8.19324 5.72822V6.30817C7.97352 6.3586 7.76103 6.39462 7.56287 6.51349C6.91454 6.79087 6.3022 7.31319 6.15812 8.0985C6.00684 8.93057 6.36704 9.67982 7.06943 10.1301C7.58088 10.4579 8.25805 10.6489 8.80199 10.8037C8.88119 10.8254 8.93167 10.847 9.04689 10.8722C9.47555 10.9874 9.92583 11.0775 10.2896 11.3585C10.7111 11.6466 10.5093 12.1582 10.0771 12.3022C9.72412 12.4644 9.26661 12.5184 8.65431 12.4284C8.23649 12.3635 7.81861 12.2194 7.41164 12.0825C7.32156 12.0537 7.23151 12.0213 7.14146 11.9925C6.73444 11.8556 6.29139 12.0789 6.15812 12.4859C6.02125 12.893 6.24457 13.3361 6.6516 13.4694C7.15948 13.6351 7.67093 13.8044 8.19324 13.9196V14.5464C8.19324 14.9751 8.54264 15.3245 8.9713 15.3245C9.3999 15.3245 9.74929 14.9751 9.74929 14.5464V13.9989C10.0699 13.9629 10.376 13.8909 10.657 13.7756C11.3342 13.5054 11.8925 12.9759 12.0438 12.169C12.1951 11.3332 11.8673 10.5516 11.1685 10.0725C10.6318 9.70506 9.91499 9.50334 9.3531 9.34125C9.27022 9.31962 9.18379 9.29438 9.10091 9.24035C8.69033 9.16115 8.26166 9.04586 7.9051 8.81889C7.45482 8.53074 7.78259 8.11289 8.17885 7.93996C8.62545 7.74908 9.17656 7.70583 9.65202 7.79588C9.90776 7.84629 10.1599 7.91833 10.4121 7.98682C10.4661 8.00483 10.5237 8.01923 10.5778 8.03362C10.992 8.14891 11.4206 7.90755 11.5359 7.49333C11.6511 7.07905 11.4062 6.65038 10.992 6.50269C10.9272 6.5171 10.8587 6.49548 10.7939 6.47747C10.4481 6.38021 10.0987 6.28295 9.74929 6.23973V5.72822Z"
                            fill="#040404"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_1084">
                            <rect
                              width="17.9988"
                              height="18.72"
                              fill="white"
                              transform="translate(0.000976562 0.916016)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    Enjoy a smooth, branded checkout experience.
                  </li>
                </ul>
                <button
                  className="cmn-btn"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  <span className="cmn-btn-text1">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                  <span className="cmn-btn-text2">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                </button>

                <div className="build_web_app-content-profile d-none">
                  <p>
                    “As a small business owner, I needed an affordable
                    e-commerce solution that didn’t compromise quality.
                    LaunchEstore delivered with easy-to-use features and
                    seamless payment integration. My business growth has been
                    incredible since launching!”
                  </p>
                  <div className="build_web_app-profile-inner d-none">
                    <div className="build_web_app-profile-img">
                      <img src={profileimg} alt="" />
                    </div>
                    <div className="build_web_app-profile-content">
                      <h4>Rakesh Patel</h4>
                      <p>Small Business Owner</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="build_web_app-image-wrp build-app-image-wrp">
                <img src={buildappimg} alt="bulid_website" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===Build-Web-App-start=== --> */}

      {/* <!-- ===WebSite-Templates-start=== --> */}
      <section className="website-templates p-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="website-templates-title">
                <h2>Choose a template designed for business growth</h2>
                <p>
                  Choose from a range of professional templates designed to help
                  your business grow. Customize easily and launch your store
                  quickly with themes built to boost engagement and drive sales.
                </p>
                <button
                  className="cmn-btn"
                  onClick={() => {
                    navigate("/portfolio");
                  }}
                >
                  <span className="cmn-btn-text1">
                    Browse Portfolio{" "}
                    <MdOutlineArrowOutward className="button-icon" />
                  </span>
                  <span className="cmn-btn-text2">
                    Browse Portfolio{" "}
                    <MdOutlineArrowOutward className="button-icon" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="website-templates-images-wrp">
              <div className="website-templates-images-1">
                <img src={websitetempletsimg1} alt="" />
              </div>
              <div className="website-templates-images-2">
                <img src={websitetempletsimg2} alt="" />
              </div>
              <div className="marquee">
                <div className="marquee__row">
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                </div>
                <div aria-hidden="true" className="marquee__row">
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                  <div className="marquee__item">
                    <p className="marquee__text">Book A Free Demo</p>
                  </div>
                  <span className="marquee__separator">&#9679;</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===WebSite-Templates-end=== --> */}

      {/* <!-- ===growth-start=== --> */}
      <section className="website_growth p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Launchestore versatile solution & Features</h2>
                <div>
                  <button
                    className="cmn-btn"
                    onClick={() => {
                      navigate("/features");
                    }}
                  >
                    <span className="cmn-btn-text1">
                      Browse Features{" "}
                      <MdOutlineArrowOutward className="button-icon" />
                    </span>
                    <span className="cmn-btn-text2">
                      Browse Features{" "}
                      <MdOutlineArrowOutward className="button-icon" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Zero IT Cost</h3>
                  <p>
                    Join thousands of businesses trusting our eCommerce
                    platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Easy Store Management</h3>
                  <p>
                    Quickly manage products & speed up tasks with your
                    dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Integrated Logistics</h3>
                  <p>
                    Increase sales with our app no restrictions, friction, or
                    limits.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Integrated Payment Gateway</h3>
                  <p>
                    Quick, secure payments with our integrated payment system.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>SEO Friendly URL </h3>
                  <p>
                    Boost traffic with SEO-friendly URLs and editable robots.txt
                    files.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Multi Branches Platform</h3>
                  <p>
                    Manage multiple brands & regions easily from a single
                    account.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Launch Mobile Applications</h3>
                  <p>
                    Launch your new store and impress customers with
                    Launchestore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Your Own Brand Websites</h3>
                  <p>
                    Easily build a website with Launchestore to showcase your
                    brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===growth-end=== --> */}

      {/* <!-- ===Our-Projects-start=== --> */}
      <section className="our_proejcts p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title our_proejcts_title">
                <h2>Explore Our Creative Portfolio</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
              <Link to="/zumkha-detail" className="our_proejcts_card our_proejcts_card-1">
                <div className="our_proejcts_card_img">
                  <img src={jewellerystoreimg} alt="our-proejcts" />
                </div>
                <div className="our_proejcts_card_content">
                  <h6>Website</h6>
                  <h4>
                    Zumkha offers timeless jewelry crafted to enhance elegance
                    and cherished moments.
                  </h4>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
              <Link to="/kishori-detail" className="our_proejcts_card our_proejcts_card-2">
                <div className="our_proejcts_card_img">
                  <img src={kishoricreationimg} alt="our-proejcts" />
                </div>
                <div className="our_proejcts_card_content">
                  <h6>Website</h6>
                  <h4>
                    Kishori Creation offers elegant, sustainable fashion
                    blending tradition and modern style.
                  </h4>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
              <Link to="/Unibest-Detail" className="our_proejcts_card our_proejcts_card-3">
                <div className="our_proejcts_card_img">
                  <img src={unibestimg} alt="our-proejcts" />
                </div>
                <div className="our_proejcts_card_content">
                  <h6>Website</h6>
                  <h4>
                  Unibest crafts quality uniforms ensuring comfort, safety, and sustainable solutions.
                  </h4>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
              <Link to="/dpdecor-detail" className="our_proejcts_card our_proejcts_card-4">
                <div className="our_proejcts_card_img">
                  <img src={interiordesigningimg} alt="our-proejcts" />
                </div>
                <div className="our_proejcts_card_content">
                  <h6>Website</h6>
                  <h4>
                  DP Decor creates bespoke interior designs, blending innovation with personalized client vision.
                  </h4>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
              <Link to="/YashIndustries_Detail" className="our_proejcts_card our_proejcts_card-5">
                <div className="our_proejcts_card_img">
                  <img src={yashindustriesimg} alt="our-proejcts" />
                </div>
                <div className="our_proejcts_card_content">
                  <h6>Website</h6>
                  <h4>
                  Yash Industries manufactures high-quality cooking appliances, offering durable, reliable solutions globally.
                  </h4>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
              <Link to="/laxmiraj-detail" className="our_proejcts_card our_proejcts_card-6">
                <div className="our_proejcts_card_img">
                  <img src={laxmirajdryfruitsimg} alt="our-proejcts" />
                </div>
                <div className="our_proejcts_card_content">
                  <h6>Website</h6>
                  <h4>
                  Laxmiraj Dry Fruits offers premium dry fruits, nuts, and spices across India.
                  </h4>
                </div>
              </Link>
            </div>
            <div className="col-12 text-center">
              <button
                className="cmn-btn"
                onClick={() => {
                  navigate("/portfolio");
                }}
              >
                <span className="cmn-btn-text1">
                  View Portfolio{" "}
                  <MdOutlineArrowOutward className="button-icon" />
                </span>
                <span className="cmn-btn-text2">
                  View Portfolio{" "}
                  <MdOutlineArrowOutward className="button-icon" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===Our-Projects-end=== --> */}

      <WebsiteStep classes={"websitestep_sub"} />

      {/* <SuccessBanner/>
      <Blog/> */}
      <LocationSlider />
    </>
  );
};

export default Home;
