import React, { useEffect } from "react";
import portfoliobanner3leftimg  from '../images/unibest-banner1.png'
import portfolibanner3rightimg1  from '../images/unibest-banner2.png'
import portfolibanner3rightimg2  from '../images/unibest-banner3.png'
import zumkhasmimg  from '../images/unibest-sm-logo.png'
import imagessectionimg1  from '../images/unibest-solution-img1.png'
import imagessectionimg2  from '../images/unibest-solution-img3.png'
import imagessectionimg3  from '../images/unibest-solution-img5.png'
import imagessectionimg4  from '../images/unibest-solution-img2.png'
import imagessectionimg5  from '../images/unibest-solution-img4.png'
import imagessectionimg6  from '../images/unibest-solution-img6.png'
import scopeworkimg1 from '../images/unibest-scop-2.png'
// import scopeworkimg2 from '../images/scope_work_img2.png'
import problemimg2 from '../images/unibest-problem.png'
import { Link } from 'react-router-dom'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'
import { UnibestSolSlider } from "./component/UnibestSolSlider";

const UnibestDetail_backup = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        {/* ===portfolio_detail_banner_3-start=== */}
        <section className='portfolio_detail_banner_3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xxl-7 col-xl-6 col-lg-6'>
                        <div className='portfolio_detail_banner_3_left_image'>
                            <img src={portfoliobanner3leftimg} alt={portfoliobanner3leftimg} />
                            <div className='portfolio_detail_banner_3_left_content'>
                                <h6>Manufacturer Store</h6>
                                <h2>Unibest: Elevating Online Retail</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-5 col-xl-6 col-lg-6'>
                        <div className='portfolio_detail_banner_3_right_image1'>
                            <img src={portfolibanner3rightimg1} alt={portfolibanner3rightimg1} />
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className='portfolio_detail_banner_3_right_content'>
                                    <img src={zumkhasmimg} alt={zumkhasmimg} />
                                    <h4>Unibest manufactures quality uniforms, textiles, and safety gear, delivering comfort, protection, and sustainable solutions.</h4>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className='portfolio_detail_banner_3_right_image2'>
                                    <img src={portfolibanner3rightimg2} alt={portfolibanner3rightimg2} />
                                    <Link href="">Get start with us Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_3-end=== */}

        {/* ===portfolio_detail_banner_3_mission_challenge-start=== */}
        <section className='portfolio_detail_3_mission_challenge p-100'>
            <div className="container">
                <div className="row">
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Mission</h3>
                            <p>Our mission in web development is to empower brands like “Unibest” with cutting-edge digital solutions. We focus on crafting seamless, user-friendly e-commerce platforms that enhance online presence and drive growth. By integrating innovative technologies and prioritizing client needs, we aim to deliver impactful, sustainable web experiences that elevate brand success.</p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Business Problem</h3>
                            <p>Unibest faced the challenge of needing a robust e-commerce platform to support their expanding product line. They required a user-friendly, scalable solution to enhance customer experience and streamline operations. Addressing these needs was crucial to maintaining their competitive edge and achieving sustainable growth in the digital marketplace.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mt-1 mt-sm-2'>
                            <h3>CMExpertise Solution</h3>
                            <p>To address Unibest's challenges, we developed a comprehensive e-commerce platform tailored to their needs. This solution included a user-friendly interface, streamlined navigation, and robust backend support to enhance operational efficiency. Our focus on scalability ensures Unibest can grow and adapt in a competitive digital marketplace seamlessly.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_3_mission_challenge-end=== */}

        {/* ===portfolio_detail_3_images_section=== */}
        <section className='portfolio_detail_3_images_section'>
            <div className='imagessectionwrp-1'>
                <img src={imagessectionimg1} alt={imagessectionimg1} className='imagessectionimg1' />
                <img src={imagessectionimg4} alt={imagessectionimg4} className='imagessectionimg4' />
            </div>
            <div className='imagessectionwrp-2'>
                <div className='imagessectionwrp-2_top'>
                    <img src={imagessectionimg2} alt={imagessectionimg2} className='imagessectionimg2' />
                    <img src={imagessectionimg3} alt={imagessectionimg3} className='imagessectionimg3' />
                </div>
                <div className='imagessectionwrp-2_bottom'>
                    <img src={imagessectionimg5} alt={imagessectionimg5} className='imagessectionimg5' />
                    <img src={imagessectionimg6} alt={imagessectionimg6} className='imagessectionimg6' />
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_3_images_section=== */}

        {/* ===portfolio_detail_2_scope_work-start=== */}
        <section className='portfolio_detail_2_scope_work p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Design Thinking Process</h3>
                            <p>The Design Thinking process at Unibest emphasizes a user-centered approach to developing our e-commerce platform. By empathizing with clients, defining their needs, ideating solutions, prototyping, and testing, we ensure a seamless online experience. This iterative methodology allows us to refine our offerings, enhance user satisfaction, and effectively showcase our high-quality uniforms and textiles.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            {/* <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_scope_work-ebd=== */}

        {/* ===portfolio_detail_3_problem_start=== */}
        <section className='portfolio_detail_3_problem'>
            <div className='row'>
                <div className='col-lg-6 '>
                    <div className='portfolio_detail_3_problem_image'>
                        <img src={problemimg2} alt={problemimg2} />
                    </div>
                </div>
                <div className='col-lg-6 align-self-center'>
                    <div className='mission-challange-research-wrp'>
                        <h3>Results and Impact</h3>
                        <p>Unibest’s e-commerce site enhances accessibility and customer engagement, allowing clients to explore and order high-performance uniforms online, boosting sales, and reinforcing our commitment to excellent service.</p>
                        <p>Unibest’s e-commerce launch revolutionized customer engagement, enabling seamless access to our quality uniforms and textiles. This shift boosted efficiency, simplified ordering, and enhanced service quality, creating a smoother experience and driving increased client satisfaction.</p>
                        <ul>
                            <li><span><i className="fa-solid fa-check"></i></span>Efficient online platform for browsing, selecting, and purchasing high-performance uniforms, promoting seamless experience.</li>
                            <li><span><i className="fa-solid fa-check"></i></span>Enhanced operational workflow by integrating digital tools, improving service delivery, & boosting sales.</li>
                            <li><span><i className="fa-solid fa-check"></i></span>Strong focus on sustainability, ensuring eco-friendly practices in manufacturing uniforms and textiles for conscious customers.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_3_problem_start=== */}

        {/* ===portfolio_detail_2_solution-start=== */}
        <section className='portfolio_detail_2_solution p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-sm-5 mb-3'>
                            <h3>Future Enhancements</h3>
                            <p>Unibest plans to enhance its e-commerce platform by integrating advanced features such as personalized shopping experiences, real-time inventory updates, and improved customer feedback mechanisms. Additionally, we aim to implement AI-driven recommendations, expand our product range, and prioritize mobile optimization to better serve our clients and meet evolving market demands.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='col-12'>
                <UnibestSolSlider/>
            </div>
        </section>
        {/* ===portfolio_detail_2_solution-end=== */}

        <Testimonial />
        <LocationSlider />
    </>
  )
}

export default UnibestDetail_backup
