import React, { useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useRef } from 'react';

function MainLayout({ children }) {
  const tawkMessengerRef = useRef();

  // const handleMinimize  = () => {
  //     tawkMessengerRef.current.minimize();
  // };
  useEffect(() => {
    // Open the chat widget after a short delay (adjust the time as needed)
    const timer = setTimeout(() => {
      if (tawkMessengerRef.current) {
        window.Tawk_API.maximize()
        // This is the trick to opening the chat widget
        // We simulate a user interaction to show the widget
        tawkMessengerRef.current.open();  // Open the chat widget
      }
    }, 1000); // Delay to ensure that Tawk widget is fully initialized

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Header />
      <main>
        {children}
      </main>
  
      <TawkMessengerReact
                propertyId="6735b8972480f5b4f59de04e"
                widgetId="1ickti4p1"
                ref={tawkMessengerRef}
                />
      <Footer />
    </div>
  );
}

export default MainLayout;
