import React from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineArrowOutward } from "react-icons/md";

const LocationSlider = () => {
  const navigate = useNavigate();
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} style={{ display: "block" }} onClick={onClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.75 12L20.25 12"
            stroke="#CC833D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5 18.75L20.25 12L13.5 5.25"
            stroke="#CC833D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.25 12L3.75 12"
            stroke="#CC833D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 18.75L3.75 12L10.5 5.25"
            stroke="#CC833D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <section className="our_location p-100 pt-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="success-section-2">
              <h2>What you do today shapes tomorrow's success.</h2>
              <button
                className="cmn-btn"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                <span className="cmn-btn-text1">
                  Let’s Start <MdOutlineArrowOutward className="button-icon" />
                </span>
                <span className="cmn-btn-text2">
                  Let’s Start <MdOutlineArrowOutward className="button-icon" />
                </span>
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="our_location_title">
              <h2>Area We Serve</h2>
            </div>
          </div>
          <div className="col-12">
            <div className="owl-carousel owl-theme our_location_slider">
              <Slider {...settings}>
                <div className="our_location_slider_content">
                  <h3>India</h3>
                  <p>902, Parshwa Tower, S.G.Highway, Ahmedabad</p>
                  {/* <Link href="#">Call us now</Link> */}
                </div>
                <div className="our_location_slider_content">
                  <h3>UAE</h3>
                  <p>Yes Business Tower, AI Barsha 1, Dubai</p>
                  {/* <Link href="#">Call us now</Link> */}
                </div>
                <div className="our_location_slider_content">
                  <h3>Sweden</h3>
                  <p>HQ.Jungmansgatan 12, 211 11 Malmö, Sweden.</p>
                  {/* <Link href="#">Call us now</Link> */}
                </div>
                <div className="our_location_slider_content">
                  <h3>Saudi Arabia</h3>
                  <p>Awttar Building, Baladia St,Jeddah - KSA</p>
                  {/* <Link href="#">Call us now</Link> */}
                </div>
                <div className="our_location_slider_content">
                  <h3>USA</h3>
                  <p>Mountain View, CA, United States, California</p>
                  {/* <Link href="#">Call us now</Link> */}
                </div>
                <div className="our_location_slider_content">
                  <h3>Germany</h3>
                  <p>Amalienstraße 7, 21073 Hamburg Germany</p>
                  {/* <Link href="#">Call us now</Link> */}
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationSlider;
