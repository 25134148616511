import React from "react";
import testimonialclient1 from "../../images/testimonial_client_1.png";
import Slider from "react-slick";

var settings2 = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 10000,
      settings: {
        slidesToShow: 5,
      },
    },
  ],
};

export const Testimonial = () => {
  return (
    <>
      {/* <!-- ===Testimonial-start=== --> */}
      <section className="testimonial p-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Launchestore Success Stories</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial_slider_wrp">
          <div className="owl-carousel owl-theme testimonial_slider">
            <Slider {...settings2}>
              <div className="testimonial_slider_card">
                <div className="testimonial_slider_card_upper_content">
                  <svg
                    className="testimonial_quote_1"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                  <h4>
                    The E-Commerce Softwares system is user friendly and pretty
                    easy to use. The system is very smooth.
                  </h4>
                  <svg
                    className="testimonial_quote_2"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                </div>
                <div className="testimonial_clinet_info">
                  <img src={testimonialclient1} alt="" />
                  <h3>Abisay Herrera</h3>
                  <p>CO-Founder</p>
                  <div className="clinet-star">
                    <ul>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="testimonial_slider_card">
                <div className="testimonial_slider_card_upper_content">
                  <svg
                    className="testimonial_quote_1"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                  <h4>
                    This guys are amazing, We have tried couple of platforms
                    before using LaunchEStore. They have simplified everything
                    from user prospective. Look and feel is best and support is
                    awsome.
                  </h4>
                  <svg
                    className="testimonial_quote_2"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                </div>
                <div className="testimonial_clinet_info">
                  <img src={testimonialclient1} alt="" />
                  <h3>Eugene Hiben</h3>
                  <p>CO-Founder</p>
                  <div className="clinet-star">
                    <ul>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="testimonial_slider_card">
                <div className="testimonial_slider_card_upper_content">
                  <svg
                    className="testimonial_quote_1"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                  <h4>
                    When I think of Intelligent eCommerce Software, I think ease
                    of use and the ability to streamline my day... It has made
                    the quality of my life better.
                  </h4>
                  <svg
                    className="testimonial_quote_2"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                </div>
                <div className="testimonial_clinet_info">
                  <img src={testimonialclient1} alt="" />
                  <h3>Rocky Dixon</h3>
                  <p> CEO</p>
                  <div className="clinet-star">
                    <ul>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="testimonial_slider_card">
                <div className="testimonial_slider_card_upper_content">
                  <svg
                    className="testimonial_quote_1"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                  <h4>
                    LaunchEstore transformed our online store! Their team made
                    the entire process seamless, and our sales have soared.
                    Highly recommend their eCommerce expertise!
                  </h4>
                  <svg
                    className="testimonial_quote_2"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.1384 0C20.5483 0 20.8356 0.313 20.8356 0.663C20.8356 0.911 20.6909 1.178 20.3398 1.365C18.5564 2.313 16.4917 5.591 16.4917 7.613C19.5 7.521 20.8066 10.195 20.8066 11.871C20.8066 13.878 19.0731 16 16.5097 16C13.2779 16 11.692 13.453 11.692 10.693C11.692 4.713 18.5085 0 20.1384 0ZM9.33306 0C9.74201 0 10.0303 0.313 10.0303 0.663C10.0303 0.911 9.88564 1.178 9.53454 1.365C7.75112 2.313 5.68641 5.591 5.68641 7.613C8.6947 7.521 10.0013 10.195 10.0013 11.871C10.0013 13.878 8.2668 16 5.70437 16C2.47165 16 0.886719 13.453 0.886719 10.693C0.886719 4.713 7.70224 0 9.33306 0Z"
                      fill="#C3C7D8"
                    />
                  </svg>
                </div>
                <div className="testimonial_clinet_info">
                  <img src={testimonialclient1} alt="" />
                  <h3>Sarah L.</h3>
                  <p>Founder</p>
                  <div className="clinet-star">
                    <ul>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                      <li>
                        <i className="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      {/* <!-- ===Testimonial-end=== --> */}
    </>
  );
};
