import React, { useEffect, useState } from 'react'
import websitestepimg1 from "../../images/website-setp-img-1.png";
import websitestepimg2 from "../../images/website-setp-img-2.png";
import websitestepimg3 from "../../images/website-setp-img-3.png";

export const WebsiteStep = (props) => {
    const {classes} = props
   
    const [currentStep, setCurrentStep] = useState(0);
    const stepDuration = 5000; // 5 seconds

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentStep((prevStep) => (prevStep + 1) % 3); // Loops through 3 steps
      }, stepDuration);
  
      return () => clearInterval(interval); // Cleanup on component unmount
    }, []);
  return (
    <>
    {/* <!-- ===How-Create-Web-App-start=== --> */}
    <section className={`how_create_web_app p-100 pb-0 ${classes}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Now Create Your Online Business Instantly</h2>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-lg-6">
              <div
                className={`${
                  currentStep === 0 && "active"
                } create_web_app_step_wrp`}
                data-step="1"
              >
                <h6>Step 1</h6>
                <h3>Create your own online store</h3>
                <p>
                  Establish your unique online presence with ease. Customize
                  your store to reflect your brand and offerings. Start selling
                  today and connect with customers worldwide.
                </p>
                <div className="progress-bar-wrapper">
                  <div className="progress-bar"></div>
                </div>
              </div>
              <div
                className={`${
                  currentStep === 1 && "active"
                } create_web_app_step_wrp`}
                data-step="2"
              >
                <h6>Step 2</h6>
                <h3>Add products & customize</h3>
                <p>
                  After setting up your online store, simply add your products
                  to showcase your offerings. Connect your bank account to
                  enable secure payment processing.{" "}
                </p>
                <div className="progress-bar-wrapper">
                  <div className="progress-bar"></div>
                </div>
              </div>
              <div
                className={`${
                  currentStep === 2 && "active"
                } create_web_app_step_wrp`}
                data-step="3"
              >
                <h6>Step 3</h6>
                <h3>Promote your ecommerce website & earn!</h3>
                <p>
                  Share your store’s URL with friends, family, and online
                  audiences to maximize your reach. Promote it on your Facebook
                  page and other social media platforms for greater visibility.
                </p>
                <div className="progress-bar-wrapper">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
                {currentStep === 0 && (
                  <img
                    id="progress-image"
                    src={websitestepimg1}
                    alt="Step Image"
                  />
                )}
                {currentStep === 1 && (
                  <img
                    id="progress-image"
                    src={websitestepimg3}
                    alt="Step Image"
                  />
                )}
                {currentStep === 2 && (
                  <img
                    id="progress-image"
                    src={websitestepimg2}
                    alt="Step Image"
                  />
                )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===How_Create_Web_App_end=== --> */}
    </>
  )
}
