import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import chatimg1 from "../images/live-chat1.png";
import chatimg2 from "../images/live-chat2.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import vendor from "./property.json";
import PhoneInput from "react-phone-input-2";
import { MdOutlineArrowOutward } from "react-icons/md";
import LocationSlider from "./LocationSlider";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import success from '../images/sucess.png'
export const noOnlyNumbers = (value) => {
  return !/^\d+$/.test(value);
};

const noOnlySpecialCharacters = (value) => {
  return /[a-zA-Z0-9]/.test(value);
};

const noOnlySpecialCharactersAndNumbers = (value) => {
  return /[a-zA-Z]/.test(value);
};
//   name: values.first_name,
//   mobile_no: Number(values.mobile_number),
//   email: values.email,
//   message: values.message,

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let url = "https://server.zumkha.com/";
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(`${vendor.full_name_error}`)
      .test("no-only-numbers", `${vendor.full_name_error_2}`, noOnlyNumbers)
      .test(
        "no-only-special-characters",
        `${vendor.full_name_error_3}`,
        noOnlySpecialCharacters
      )
      .test(
        "no-only-special-characters-and-numbers",
        `${vendor.full_name_error_4}`,
        (value) => noOnlySpecialCharactersAndNumbers(value)
      ),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please Enter Email"),
    mobile_no: Yup.string().required(`${vendor.phone_error}`),
    message: Yup.string()
      .min(10, "Message must be at least 10 characters")
      .required("Please Enter Message"),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const params = {
      name: values.name,
      mobile_no: values.code + values.mobile_no,
      email: values.email,
      message: values.messagee,
    };
    setSubmitting(true);
    try {
      const response = await fetch(`${url}user/contact_us_superadmin`, {
        // Corrected URL syntax
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setSubmitting(false);
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        resetForm();
      }, 3000);

      console.log("Data submitted successfully:", result);

      return result;
    } catch (error) {
      console.error("Error submitting data:", error.message);
      console.log("Failed to submit data. Please try again later.");
      return null;
    }
  };

  const handleMailClick = () => {
    // Mailto URL with pre-filled subject, recipient, and body
    const mailtoUrl = 'mailto:notify.launchestore@gmail.com';
    
    // Open the default email client (will trigger mailto URL)
    window.location.href = mailtoUrl;
  };

  const handalchat =() =>{
    window.Tawk_API.maximize()
  }

  return (
    <>
      {/* ======== contact-banner-start ========= */}
      <section className="banner_section contactus-banner">
        <div className="container">
          <div className="contact-banner-content">
            <h1>Get in Touch with Us</h1>
            <ul>
              <li>
                <Link href="#">Build Your Brand</Link>
              </li>
              <li>
                <Link href="#">Expand Your Reach</Link>
              </li>
              <li>
                <Link href="#">Connect and Thrive</Link>
              </li>
              <li>
                <Link href="#">Propel Your Business</Link>
              </li>
            </ul>
            <div className="text-center">
              <button
                className="cmn-btn"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                <span className="cmn-btn-text1">
                  Sign Up <MdOutlineArrowOutward className="button-icon" />
                </span>

                <span className="cmn-btn-text2">
                  Sign Up <MdOutlineArrowOutward className="button-icon" />
                </span>
              </button>
            </div>
            <h6>
              Launch your <span>e-commerce </span>store today
            </h6>
          </div>
        </div>
      </section>
      {/* ======== contact-banner-end ========= */}

      {/* ======== contact-form-start =========== */}
      <section className="contact-form-wrp">
        <div className="container">
          <div className="contact-form">
            <h3>Get in touch</h3>
            <p>
              Please fill out the form below. We do our best to respond within 1
              business day.
            </p>
            <div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                  mobile_no: "",
                  code: "91",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values, setFieldValue }) => (
                  <Form>
                    <div className="input-form">
                      {/* <label htmlFor="name">Name</label> */}
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Full Name"
                        maxLength={150}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div className="input-form">
                      {/* <label htmlFor="email">Email</label> */}
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        maxLength={150}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div className="input-form">
                      {/* <label>Phone Number</label> */}
                      <PhoneInput
                        country={"us"}
                        value={values.code + values.mobile_no}
                        onChange={(value, data) => {
                          setFieldValue(
                            "mobile_no",
                            value.slice(data.dialCode.length)
                          );
                          setFieldValue("code", data.dialCode);
                        }}
                      />
                      <ErrorMessage
                        name="mobile_no"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div className="input-form">
                      {/* <label htmlFor="message">Message</label> */}
                      <Field
                        as="textarea"
                        id="message"
                        name="message"
                        placeholder="Message"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <button
                      className="cmn-btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <span className="cmn-btn-text1">
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </span>
                      <span className="cmn-btn-text2">
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </span>
                    </button>
                    {isSubmitted &&
                       <div className="success-model-wrp">
                       <div className="success-modal">
                           <div className="success-img text-center">
                             <img src={success} alt="success" />
                           </div>
                           <h4>Success</h4>
                           <p> "Your message has been sent successfully , Will get back to you soon"</p>
                           {/* <Link to="/" className="success-ok-btn">ok</Link> */}
                       </div>
                     </div>}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      {/* ======== contact-form-end =========== */}

      {/* ======= contact-chat-start ====== */}
      <section className="contact-chat p-100">
        <div className="container">
          <div className="build_web_app-title">
            <h2>Can’t find what you need? Here’s how to contact us.</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-lg-0 mb-4">
              <div className="chat-data">
                <div className="chat-left">
                  <h4>Email Us</h4>
                  <p>
                    Send us a message and read our answer when it’s convenient
                    for you.
                  </p>
                  <ul>
                    <li>24 hours a day</li>
                    <li>7 days a week</li>
                  </ul>
                  <div className="">
                    <button className="cmn-btn">
                      {/* <span className="cmn-btn-text1">
                        Send Email <i className="fa-light fa-arrow-right"></i>
                      </span>
                      <span className="cmn-btn-text2">
                        Send Email <i className="fa-solid fa-arrow-right"></i>
                      </span> */}
                      <span className="cmn-btn-text1" onClick={handleMailClick}>
                        Send Email <MdOutlineArrowOutward className="button-icon" />
                      </span>

                      <span className="cmn-btn-text2" onClick={handleMailClick}>
                        Send Email <MdOutlineArrowOutward className="button-icon" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="chat-img">
                  <img src={chatimg1} alt="live_chat" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="chat-data">
                <div className="chat-left">
                  <h4>Live Chat</h4>
                  <p>
                    Real-time conversations & Quick answers from our
                    award-winning Customer Support team.
                  </p>
                  <ul>
                    <li>Monday to Friday</li>
                    <li>10 AM to 8 PM IST </li>
                  </ul>
                  <div className="">
                    <button className="cmn-btn" onClick={handalchat}>
                      <span className="cmn-btn-text1">
                        Chat With Us <MdOutlineArrowOutward className="button-icon" />
                      </span>

                      <span className="cmn-btn-text2">
                        Chat With Us <MdOutlineArrowOutward className="button-icon" />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="chat-img">
                  <img src={chatimg2} alt="live_chat" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= contact-chat-end ====== */}

      <LocationSlider />
    </>
  );
};

export default Contactus;
