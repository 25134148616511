import React from 'react'
import portfoliobanner3leftimg  from '../images/portfoli_banner3_left_img.png'
import portfolibanner3rightimg1  from '../images/portfoli_banner3_right_img1.png'
import portfolibanner3rightimg2  from '../images/portfoli_banner3_right_img2.png'
import zumkhasmimg  from '../images/zumkha_sm_img.png'
import imagessectionimg1  from '../images/images_section_img1.png'
import imagessectionimg2  from '../images/images_section_img2.png'
import imagessectionimg3  from '../images/images_section_img3.png'
import imagessectionimg4  from '../images/images_section_img4.png'
import imagessectionimg5  from '../images/images_section_img5.png'
import imagessectionimg6  from '../images/images_section_img6.png'
import scopeworkimg1 from '../images/scope_work_img1.png'
import scopeworkimg2 from '../images/scope_work_img2.png'
import problemimg2 from '../images/problem_img2.png'
import { Link } from 'react-router-dom'
import { SolutionSlider } from './component/SolutionSlider'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'

const PortfolioDetail_3 = () => {
  return (
    <>
        {/* ===portfolio_detail_banner_3-start=== */}
        <section className='portfolio_detail_banner_3'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xxl-7 col-xl-6 col-lg-6'>
                        <div className='portfolio_detail_banner_3_left_image'>
                            <img src={portfoliobanner3leftimg} alt={portfoliobanner3leftimg} />
                            <div className='portfolio_detail_banner_3_left_content'>
                                <h6>Jewellery Store</h6>
                                <h2>Zumkha - Luxury in Every Detail</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-5 col-xl-6 col-lg-6'>
                        <div className='portfolio_detail_banner_3_right_image1'>
                            <img src={portfolibanner3rightimg1} alt={portfolibanner3rightimg1} />
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className='portfolio_detail_banner_3_right_content'>
                                    <img src={zumkhasmimg} alt={zumkhasmimg} />
                                    <h4>Unibest is a best clothing selling app of uniforms, textiles, linen, safety items, and promotional items.</h4>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className='portfolio_detail_banner_3_right_image2'>
                                    <img src={portfolibanner3rightimg2} alt={portfolibanner3rightimg2} />
                                    <Link href="">Get start with us Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_3-end=== */}

        {/* ===portfolio_detail_banner_3_mission_challenge-start=== */}
        <section className='portfolio_detail_3_mission_challenge p-100'>
            <div className="container">
                <div className="row">
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Mission</h3>
                            <p>The mission of the clothing eCommerce platform is to provide a seamless shopping experience by offering accurate sizing tools, reducing returns, and enhancing customer satisfaction. It aims to simplify the checkout process to decrease cart abandonm-ent while building trust through high-quality product representation. </p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Challenge</h3>
                            <p>A key challenge in clothing eCommerce is addressing sizing and fit issues, which often lead to high return rates and customer dissatisfaction. Additionally, many retailers face high cart abandonment due to complex checkout processes or doubts about product quality. Effective inventory management is also critical.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mt-1 mt-sm-2'>
                            <h3>Research</h3>
                            <p>The research focused on understanding user behavior, particularly the challenges they face with sizing and fit when shopping online. Surveys and user interviews revealed high rates of cart abandonment due to complex checkout processes and uncertainty about product quality.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_3_mission_challenge-end=== */}

        {/* ===portfolio_detail_3_images_section=== */}
        <section className='portfolio_detail_3_images_section'>
            <div className='imagessectionwrp-1'>
                <img src={imagessectionimg1} alt={imagessectionimg1} className='imagessectionimg1' />
                <img src={imagessectionimg4} alt={imagessectionimg4} className='imagessectionimg4' />
            </div>
            <div className='imagessectionwrp-2'>
                <div className='imagessectionwrp-2_top'>
                    <img src={imagessectionimg2} alt={imagessectionimg2} className='imagessectionimg2' />
                    <img src={imagessectionimg3} alt={imagessectionimg3} className='imagessectionimg3' />
                </div>
                <div className='imagessectionwrp-2_bottom'>
                    <img src={imagessectionimg5} alt={imagessectionimg5} className='imagessectionimg5' />
                    <img src={imagessectionimg6} alt={imagessectionimg6} className='imagessectionimg6' />
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_3_images_section=== */}

        {/* ===portfolio_detail_2_scope_work-start=== */}
        <section className='portfolio_detail_2_scope_work p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Scope of Work</h3>
                            <p>The scope of work for the clothing eCommerce project includes designing an intuitive user interface that simplifies product discovery and checkout, integrating accurate sizing tools to reduce returns, and improving product presentation to build customer trust. It also involves implementing an efficient inventory management system, optimizing the mobile shopping experience, and conducting usability testing to ensure a seamless user journey from browsing to purchase.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_scope_work-ebd=== */}

        {/* ===portfolio_detail_3_problem_start=== */}
        <section className='portfolio_detail_3_problem'>
            <div className='row'>
                <div className='col-lg-6 '>
                    <div className='portfolio_detail_3_problem_image'>
                        <img src={problemimg2} alt={problemimg2} />
                    </div>
                </div>
                <div className='col-lg-6 align-self-center'>
                    <div className='mission-challange-research-wrp'>
                        <h3>Problem</h3>
                        <p>The main problem for the clothing eCommerce platform is the high return rate due to sizing and fit issues, which impacts customer satisfaction and operational costs. </p>
                        <p>Additionally, cart abandonment is a major concern, with many users not completing their purchases due to complex checkout processes and doubts about product quality. Inefficient inventory management also leads to frequent stockouts or overstocking, affecting sales and customer experience.</p>
                        <ul>
                            <li><span><i className="fa-solid fa-check"></i></span>Identifying and Appealing to the Perfect Client: Right now, an online store is facing a growth in buyer needs.</li>
                            <li><span><i className="fa-solid fa-check"></i></span>New Technology: Using the latest technology is not always simple for vendors.</li>
                            <li><span><i className="fa-solid fa-check"></i></span>Brutal competition: many brands are entering the online business, so it's becoming tougher to create a brand for yourself.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_3_problem_start=== */}

        {/* ===portfolio_detail_2_solution-start=== */}
        <section className='portfolio_detail_2_solution p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-sm-5 mb-3'>
                            <h3>Solution</h3>
                            <p>The solution involves implementing a user-friendly interface with enhanced sizing tools to help customers choose the right fit, reducing return rates. Simplifying the checkout process and providing clear product information and visuals will build trust and lower cart abandonment. Additionally, an optimized inventory management system will ensure better stock control, reducing stockouts and overstock, leading to a smoother shopping experience and increased sales.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='col-12'>
                <SolutionSlider/>
            </div>
        </section>
        {/* ===portfolio_detail_2_solution-end=== */}

        <Testimonial />
        <LocationSlider />
    </>
  )
}

export default PortfolioDetail_3
