import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import signimg from "../images/les-img.png";
import vendor from "./property.json";
import success from '../images/sucess.png'
import errorimg from '../images/errorimg.png'
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
// Define your initial values here
const initialFormValues = {
  domain_type: "1",
  domain_name: "",
  name: "",
  ownername: "",
  email: "",
  mobile_number: "",
  code: "91",
  password: "",
  confirm_Pass: "",
  image: "",
  store_type: "",
  location: "",
  theme_name: "",
};

export const noOnlyNumbers = (value) => {
  return !/^\d+$/.test(value);
};

const noOnlySpecialCharacters = (value) => {
  return /[a-zA-Z0-9]/.test(value);
};

const noOnlySpecialCharactersAndNumbers = (value) => {
  return /[a-zA-Z]/.test(value);
};

const validationSchemaStep1 = Yup.object().shape({
  domain_type: Yup.string().required(`${vendor.domain_type_error}`),

  domain_name: Yup.string()
    .required(`${vendor.domain_name_error}`)
    .matches(
      /^(\.?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,7})$/,
      "Invalid domain name format"
    )
    .test(
      "no-numbers-and-special-chars-only",
      "Domain name cannot contain only numbers or special characters before the extension",
      (value) => {
        if (!value) return false;
        const domainPart = value.startsWith(".")
          ? value.split(".")[1]
          : value.split(".")[0];
        return /^[a-z0-9A-Z]+(\.[a-zA-Z0-9]+)*$/.test(domainPart); // Must start with a letter and may include dots followed by alphanumeric characters
      }
    ),
  name: Yup.string()
    .required(`${vendor.shop_name_error}`)
    .test("no-only-numbers", `${vendor.shop_name_error_2}`, noOnlyNumbers)
    .test(
      "no-only-special-characters",
      `${vendor.shop_name_error_3}`,
      noOnlySpecialCharacters
    )
    .test(
      "no-only-special-characters-and-numbers",
      `${vendor.shop_name_error_4}`,
      (value) => noOnlySpecialCharactersAndNumbers(value)
    ),
  ownername: Yup.string()
    .required(`${vendor.shop_owner_name_error}`)
    .test("no-only-numbers", `${vendor.shop_owner_name_error_2}`, noOnlyNumbers)
    .test(
      "no-only-special-characters",
      `${vendor.shop_owner_name_error_3}`,
      noOnlySpecialCharacters
    )
    .test(
      "no-only-special-characters-and-numbers",
      `${vendor.shop_owner_name_error_4}`,
      (value) => noOnlySpecialCharactersAndNumbers(value)
    ),
  email: Yup.string()
    .email(`${vendor.email_error_2}`)
    .required(`${vendor.email_error}`),
  image: Yup.mixed().required(`${vendor.shop_image_error}`),

  password: Yup.string()
    .required(`${vendor.password_error}`)
    .min(6, `${vendor.password_error2}`),
  confirm_Pass: Yup.string()
    .oneOf([Yup.ref("password"), undefined], `${vendor.confirm_pass_error2}`)
    .required(`${vendor.confirm_pass_error}`),
  mobile_number: Yup.string().required(`${vendor.phone_error}`),
  store_type: Yup.string().required(`${vendor.store_type_error}`),
});

const validationSchemaStep2 = Yup.object().shape({
  theme_name: Yup.string().required("Please select a theme"),
});

const Signup = () => {
  let url = "https://server.zumkha.com/";
  // let url = "http://192.168.1.48:3000/"

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initialFormValues);
  const [themes, setThemes] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [passwordShow, setPasswordShow] = useState(false);
  const [CpasswordShow, setCpasswordShow] = useState(false);
  const [successModal,setSuccessModal] = useState(false);
  const [ErrorModal,setErrorModal] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNext = (values) => {
    console.log("values", values);
    setFormData({ ...formData, ...values });
    setStep(2); // Move to the next step
  };
  const handleSubmit = (values) => {
    let OtherData = {
      locality: "0",
      language_support: "0",
      login_type: 0,
      database: "",
      display_price_with_gst: "0",
      longitude: longitude,
      latitude: latitude,
      address: formData.location,
    };
    formData.mobile_no = `${formData.code}${formData.mobile_no}`;

    const completeData = { ...formData, ...values, ...OtherData };

    // Create FormData object
    const formDataObj = new FormData();

    // Append each field to FormData
    for (const key in completeData) {
      formDataObj.append(key, completeData[key]);
    }

    // Use FormData in the fetch request
    fetch(`${url}vendor/createStore`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json", // Remove this line for FormData
        enctype: "multipart/form-data",
      },
      body: formDataObj, // Pass FormData as the body
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data",data)
        if(data.success){
          setSuccessModal(true)
        }else{
          setErrorModal(true)
          setErrorMessage(data.message)
        }
     
        // toast.success("you have successfully created your store");
        // setTimeout(() => {

        //   navigate("/");
        // }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSelect = (place) => {
    // Use the place_id from the selected place to get the location details
    geocodeByPlaceId(place.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log(place.label, lat, lng); // Pass the label, latitude, and longitude to the onChange callback
        setLongitude(lng);
        setLatitude(lat);
      });
  };

  React.useEffect(() => {
    const fetchStore = async () => {
      try {
        const response = await fetch(`${url}allStoreType`);
        if (!response.ok) {
          throw new Error("Failed to fetch themes");
        }
        const data = await response.json();
        setAllStores(data.data);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchStore();
  }, []);

  React.useEffect(() => {
    if (step === 2) {
      const fetchThemes = async () => {
        try {
          const response = await fetch(`${url}allTheme`);
          if (!response.ok) {
            throw new Error("Failed to fetch themes");
          }

          const data = await response.json();
          setThemes(data.data); // Assuming the response has an array of themes
        } catch (error) {
          console.error("Error fetching themes:", error);
        }
      };

      fetchThemes();
    }
  }, [step]);

  return (
    <>
      <section id="cardSection" className="main-content main-les-form">
        <div className="row justify-content-center">
          <div className={`${step === 2 ? "d-none" : "col-lg-6"}`}>
            <div className="les-img">
              <img src={signimg} alt="signimg" />
            </div>
          </div>
          <div
            className={`${
              step === 2 ? "col-12" : "col-xl-6 col-lg-6 col-md-12"
            }`}
          >
            <div className={`${step === 2 ? "radio-tab-wrap" : "main-form"}`}>
              {step === 1 && (
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchemaStep1}
                  onSubmit={handleNext}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="form-radio">
                        <h5>Choose Domain</h5>
                        <div className="d-flex radio-wrap">
                          <div className="d-flex align-items-start">
                            <Field
                              type="radio"
                              name="domain_type"
                              selected
                              value="1"
                              className="form-check-input"
                              id="domain_type1"
                            />
                            <label htmlFor={"domain_type1"}>
                              <h6>Use a New Domain </h6>
                              <p>
                                Register a brand-new domain for your online
                                store.
                              </p>
                            </label>
                          </div>
                          <div className="d-flex align-items-start">
                            <Field
                              type="radio"
                              name="domain_type"
                              value="2"
                              className="form-check-input"
                              id="domain_type2"
                            />
                            <label htmlFor={"domain_type2"}>
                              <h6>Use a Free Subdomain</h6>
                              <p>
                                Register a brand-new domain for your online
                                store.
                              </p>
                            </label>
                          </div>
                        </div>
                        <ErrorMessage name="domain_type" component="div" />
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-form">
                            <Field
                              type="text"
                              name="domain_name"
                              placeholder="Domain Name"
                              maxLength={150}
                            />
                            <ErrorMessage
                              name="domain_name"
                              className="error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-form">
                            {/* <label>Shop Name</label> */}
                            <Field
                              type="text"
                              name="name"
                              placeholder="Shop Name"
                              maxLength={150}
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-form">
                            {/* <label>Owner Name</label> */}
                            <Field
                              type="text"
                              name="ownername"
                              placeholder="Owner Name"
                              maxLength={150}
                            />
                            <ErrorMessage
                              name="ownername"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-form">
                            {/* <label>Phone Number</label> */}
                            <PhoneInput
                              name="mobile_number"
                              placeholder="Phone Number"
                              onChange={(value, data) => {
                                console.log(data);
                                setFieldValue(
                                  "mobile_number",
                                  value.slice(data.dialCode.length)
                                );
                                setFieldValue("code", data.dialCode);
                              }}
                              value={values.code + values.mobile_number}
                            />
                            <ErrorMessage
                              name="mobile_number"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-form">
                            {/* <label>Email</label> */}
                            <Field
                              type="email"
                              name="email"
                              placeholder="Email Address"
                              maxLength={150}
                            />
                            <ErrorMessage
                              name="email"
                              className="error"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-form signup-password-wrp">
                            {/* <label>Password</label> */}
                            <div className="position-relative">
                              <Field
                                type={passwordShow ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                maxLength={150}
                              />
                              <span
                                className={`fa fa-fw ${
                                  passwordShow ? "fa-eye" : "fa-eye-slash"
                                } field-icon toggle-password`}
                                onClick={() => setPasswordShow(!passwordShow)}
                              ></span>
                            </div>
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-form signup-password-wrp">
                            {/* <label>Confirm Password</label> */}
                            <div className="position-relative">
                              <Field
                                type={CpasswordShow ? "text" : "password"}
                                name="confirm_Pass"
                                placeholder="Confirm Password"
                                maxLength={150}
                              />
                              <span
                                className={`fa fa-fw ${
                                  CpasswordShow ? "fa-eye" : "fa-eye-slash"
                                } field-icon toggle-password`}
                                onClick={() => setCpasswordShow(!CpasswordShow)}
                              ></span>
                            </div>
                            <ErrorMessage
                              name="confirm_Pass"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-form">
                            {/* <label>image</label> */}
                            <input
                              type="file"
                              onChange={(event) =>
                                setFieldValue(
                                  "image",
                                  event.currentTarget.files[0]
                                )
                              }
                            />
                            <ErrorMessage
                              name="image"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-form select-label">
                            {/* <label>Type</label> */}
                            <Field as="select" name="store_type">
                              <option value={" "}>{"Select"}</option>
                              {allStores?.map((item, i) => (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="store_type"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-form location">
                            {/* <label>Location</label> */}
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyBW43KgTNs_Kusuvbian6KYGi_QzXOLS4w" // Replace with your actual API key
                              selectProps={{
                                placeholder: "Search Places...",
                                onChange: (place) => {
                                  handleSelect(place);
                                  setFieldValue("location", place.label);
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="button-wrapper">
                        <button className="btn next mt-4 w-100 " type="submit">
                          choose a theme{" "}
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_843_171)">
                              <path
                                d="M17.5 7L7.5 17"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M8.5 7H17.5V16"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_843_171">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  transform="translate(0.5)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              {step === 2 && (
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchemaStep2}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, values }) => (
                    <Form>
                      <div className="container">
                        <div className="d-flex align-items-center select-theme-header">
                          <span
                            className="cancel-btn back mt-5"
                            onClick={() => setStep(1)}
                          >
                            {" "}
                            <svg
                              width="15"
                              height="12"
                              viewBox="0 0 15 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.6255 6.00033C14.6255 6.1578 14.5629 6.30883 14.4516 6.42018C14.3402 6.53153 14.1892 6.59408 14.0317 6.59408H2.40238L6.73305 10.924C6.78821 10.9792 6.83197 11.0447 6.86183 11.1167C6.89168 11.1888 6.90705 11.2661 6.90705 11.3441C6.90705 11.4221 6.89168 11.4993 6.86183 11.5714C6.83197 11.6435 6.78821 11.709 6.73305 11.7642C6.67788 11.8193 6.61239 11.8631 6.54031 11.8929C6.46823 11.9228 6.39098 11.9382 6.31297 11.9382C6.23495 11.9382 6.1577 11.9228 6.08562 11.8929C6.01355 11.8631 5.94805 11.8193 5.89289 11.7642L0.549139 6.42041C0.493934 6.36526 0.45014 6.29978 0.42026 6.2277C0.39038 6.15562 0.375 6.07836 0.375 6.00033C0.375 5.9223 0.39038 5.84504 0.42026 5.77296C0.45014 5.70088 0.493934 5.6354 0.549139 5.58025L5.89289 0.236502C6.0043 0.12509 6.15541 0.0625 6.31297 0.0625C6.47053 0.0625 6.62163 0.12509 6.73305 0.236502C6.84446 0.347914 6.90705 0.49902 6.90705 0.65658C6.90705 0.81414 6.84446 0.965247 6.73305 1.07666L2.40238 5.40658H14.0317C14.1892 5.40658 14.3402 5.46914 14.4516 5.58049C14.5629 5.69184 14.6255 5.84286 14.6255 6.00033Z"
                                fill="black"
                              />
                            </svg>{" "}
                            back
                          </span>
                          <h2>Popular Designs Templates</h2>
                        </div>

                        <div className="row">
                          {themes.length === 0 ? (
                            <p>Loading themes...</p>
                          ) : (
                            themes.map((theme, i) => (
                              <div className="col-lg-4 col-md-6 mt-4">
                                <label className="custom-radio" key={i}>
                                  {/* Normal HTML radio input */}
                                  <input
                                    type="radio"
                                    name="theme_name"
                                    value={theme.name} // Use theme name or unique value
                                    checked={values.theme_name === theme.name} // Formik state tracking
                                    onChange={(e) =>
                                      setFieldValue(
                                        "theme_name",
                                        e.target.value
                                      )
                                    } // Manually set Formik value
                                  />
                                  <span className="radio-btn card">
                                    <svg
                                      className="check"
                                      width="25"
                                      height="24"
                                      viewBox="0 0 25 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.46875"
                                        width="24"
                                        height="24"
                                        rx="12"
                                        fill="#2DCA76"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.2124 6.78007C19.3765 6.95945 19.4688 7.20271 19.4688 7.45635C19.4688 7.71 19.3765 7.95326 19.2124 8.13264L10.8934 17.2199C10.7291 17.3993 10.5065 17.5 10.2743 17.5C10.0421 17.5 9.81936 17.3993 9.65514 17.2199L5.71457 12.9154C5.55505 12.735 5.46679 12.4934 5.46878 12.2426C5.47078 11.9918 5.56287 11.7519 5.72523 11.5745C5.88759 11.3972 6.10722 11.2966 6.33683 11.2944C6.56643 11.2922 6.78763 11.3886 6.95278 11.5629L10.2743 15.1911L17.9741 6.78007C18.1384 6.60074 18.3611 6.5 18.5933 6.5C18.8255 6.5 19.0481 6.60074 19.2124 6.78007Z"
                                        fill="white"
                                      />
                                    </svg>
                                    <div className="hobbies-icon">
                                      <img
                                        width={150}
                                        src={theme.themeImageUrl}
                                        alt={theme.name}
                                      />
                                    </div>
                                    <h3>{theme.name}</h3>
                                  </span>
                                </label>
                              </div>
                            ))
                          )}
                        </div>
                        <ErrorMessage name="theme_name" component="div" />
                        <div className="button-wrapper text-center">
                          <button
                            type="submit"
                            className="btn next mt-4 submit-button"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </section>

{
      successModal &&
      <div className="success-model-wrp">
        <div className="success-modal">
            <div className="success-img text-center">
              <img src={success} alt="success" />
            </div>
            <h4>Success</h4>
            <p>You have successfully created your store , Please check you mail </p>
            <Link to="/" className="success-ok-btn">ok</Link>
        </div>
      </div>
      }
      {
      ErrorModal &&
      <div className="success-model-wrp">
        <div className="success-modal">
            <div className="success-img text-center">
              <img src={errorimg} alt="errorimg" />
            </div>
            <h4>Error</h4>
            <p>{errorMessage}</p>
            <span onClick={()=>{setStep(1);setErrorModal(false);setErrorMessage("")}} className="success-ok-btn">ok</span>
        </div>
      </div>
      }
    </>
  );
};

export default Signup;
