import React, { useEffect } from "react";
import portfolioclientimg1 from '../images/portfolio_client_img1.png'
import portfolioclientimg2 from '../images/portfolio_client_img2.png'
import portfolioclientimg3 from '../images/portfolio_client_img3.png'
import portfolioclientimg4 from '../images/portfolio_client_img4.png'
import portfolibanner2img from '../images/portfoli_banner2_img.png'
import overviewappimg from '../images/overview_app_img.png'
import portfoliodetail2challengeimg from '../images/portfolio_detail_2_challenge_img.png'
import portfoliodetail2researchimg from '../images/portfolio_detail_2_research_img.png'
import scopeworkimg1 from '../images/scope_work_img1.png'
import scopeworkimg2 from '../images/scope_work_img2.png'
import problemimg1 from '../images/problem_img1.png'
import problemimg2 from '../images/problem_img2.png'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'
import { KishoriSolSlider } from "./component/KishoriSolSlider";

const Kishoricreation_Detail = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        {/* ===portfolio_detail_banner_2-start=== */}
        <section className='portfolio_detail_banner_2'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='portfolio_detail_banner_2_content'>
                            <h5>Fashion Store</h5>
                            <h2>Crafting Kishori's Fashion Elegance</h2>
                            <p>Kishori Creation seamlessly blends traditional elegance with modern fashion, offering unique attire for every occasion. With a focus on individuality and craftsmanship, their collections—ranging from kurtas to midis—exude quality and sustainability, ensuring style that respects both people and the planet.</p>
                            <div className='keyword_wrp'>
                                <h6>Keywords:</h6>
                                <ul>
                                    <li>Versatility</li>
                                    <li>Elegance</li>
                                    <li>Sustainability</li>
                                    <li>Craftsmanship</li>
                                    <li>Design</li>
                                </ul>
                            </div>
                            <div className='portfolio_detail_banner_client'>
                                <ul>
                                    <li><img src={portfolioclientimg1} alt={portfolioclientimg1} /></li>
                                    <li><img src={portfolioclientimg2} alt={portfolioclientimg2} /></li>
                                    <li><img src={portfolioclientimg3} alt={portfolioclientimg3} /></li>
                                    <li><img src={portfolioclientimg4} alt={portfolioclientimg4} /></li>
                                </ul>
                                <h4>1000+ Clients</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 align-self-center'>
                        <div className='portfolio_detail_banner_2_image'>
                            <img src={portfolibanner2img} alt={portfolibanner2img} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_banner_2-end=== */}

        {/* ===portfolio_detail_2_mission-start=== */}
        <section className='portfolio_detail_2_mission p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='overview_app_title portfolio_detail_2_mission_title'>
                            <h2>The Mission</h2>
                            <p>Our mission in web development is to craft seamless, engaging digital experiences that reflect Kishori Creation's blend of tradition and modernity. We aim to enhance their online presence, ensuring it captures their brand essence, supports sustainable practices, and offers users an intuitive, satisfying journey through their fashion offerings.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='overview_app_image_wrp'>
                            <img src={overviewappimg} alt={overviewappimg} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_mission-end=== */}

        {/* ===portfolio_detail_2_challenge-start=== */}
        <section className='portfolio_detail_2_challenge p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='portfolio_detail_2_challenge_img'>
                            <img src={portfoliodetail2challengeimg} alt={portfoliodetail2challengeimg} />
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="build_web_app-content-wrp">
                            <h3>Business Problem</h3>
                            <p>Kishori Creation faced challenges in establishing a robust online presence that effectively showcased their unique blend of traditional and modern fashion. Their previous website lacked user-friendly navigation, mobile optimization, and a cohesive design, hindering customer engagement and limiting their ability to reach a broader audience in the competitive e-commerce landscape.</p>
                            <ul>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1066)">
                                        <path
                                            d="M7.71478 6.79039C7.71478 7.75929 6.85098 8.54539 5.78632 8.54539H1.92942C0.86436 8.54539 0.000976562 7.75929 0.000976562 6.79039V3.28039C0.000976562 2.31112 0.86436 1.52539 1.92942 1.52539H5.78632C6.85098 1.52539 7.71478 2.31112 7.71478 3.28039V6.79039ZM7.71478 16.1504C7.71478 17.1193 6.85098 17.9054 5.78632 17.9054H1.92942C0.86436 17.9054 0.000976562 17.1193 0.000976562 16.1504V12.6404C0.000976562 11.6715 0.86436 10.8854 1.92942 10.8854H5.78632C6.85098 10.8854 7.71478 11.6715 7.71478 12.6404V16.1504ZM10.286 3.28039C10.286 2.31112 11.1498 1.52539 12.2145 1.52539H16.0714C17.136 1.52539 17.9998 2.31112 17.9998 3.28039V6.79039C17.9998 7.75929 17.136 8.54539 16.0714 8.54539H12.2145C11.1498 8.54539 10.286 7.75929 10.286 6.79039V3.28039ZM17.9998 16.1504C17.9998 17.1193 17.136 17.9054 16.0714 17.9054H12.2145C11.1498 17.9054 10.286 17.1193 10.286 16.1504V12.6404C10.286 11.6715 11.1498 10.8854 12.2145 10.8854H16.0714C17.136 10.8854 17.9998 11.6715 17.9998 12.6404V16.1504Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1066">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.355469)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    Limited Online Visibility
                                </li>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1071)">
                                        <path
                                            d="M8.17425 1.07897C8.69809 0.845747 9.30271 0.845747 9.82655 1.07897L17.5112 4.49922C17.81 4.63125 17.9998 4.91901 17.9998 5.20677C17.9998 5.55209 17.81 5.83984 17.5112 5.97526L9.82655 9.39455C9.30271 9.62812 8.69809 9.62812 8.17425 9.39455L0.490672 5.97526C0.192144 5.83984 0.000976562 5.55209 0.000976562 5.20677C0.000976562 4.91901 0.192109 4.63125 0.490672 4.49922L8.17425 1.07897ZM17.5112 8.83259C17.81 8.96456 17.9998 9.25235 17.9998 9.54013C17.9998 9.88544 17.81 10.1732 17.5112 10.3086L9.82655 13.7279C9.30271 13.9614 8.69809 13.9614 8.17425 13.7279L0.490672 10.3086C0.192144 10.1732 0.000976562 9.88544 0.000976562 9.54013C0.000976562 9.25235 0.192109 8.96456 0.490672 8.83259L2.36086 7.99974L7.70319 10.3763C8.52581 10.7453 9.47499 10.7453 10.2976 10.3763L15.641 7.99974L17.5112 8.83259ZM10.2976 14.7097L15.641 12.3331L17.5112 13.1659C17.81 13.2979 17.9998 13.5857 17.9998 13.8735C17.9998 14.2188 17.81 14.5065 17.5112 14.6419L9.82655 18.0612C9.30271 18.2948 8.69809 18.2948 8.17425 18.0612L0.490672 14.6419C0.192144 14.5065 0.000976562 14.2188 0.000976562 13.8735C0.000976562 13.5857 0.192109 13.2979 0.490672 13.1659L2.36086 12.3331L7.70319 14.7097C8.52581 15.0787 9.47499 15.0787 10.2976 14.7097Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1071">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.544678)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    Poor User Experience
                                </li>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1077)">
                                        <path
                                            d="M9.0004 19.3453C4.02962 19.3453 0.000976562 15.1775 0.000976562 10.0349C0.000976562 4.89244 4.02962 0.724609 9.0004 0.724609C13.9712 0.724609 17.9998 4.89244 17.9998 10.0349C17.9998 15.1775 13.9712 19.3453 9.0004 19.3453ZM8.15669 10.0349C8.15669 10.3259 8.29729 10.5987 8.53286 10.7296L11.9076 13.0572C12.2943 13.3554 12.8181 13.2463 13.0466 12.8462C13.3349 12.4462 13.2294 11.9043 12.8427 11.6352L9.84411 9.56945V5.08883C9.84411 4.60513 9.46794 4.21599 8.96879 4.21599C8.53286 4.21599 8.12508 4.60513 8.12508 5.08883L8.15669 10.0349Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1077">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.724609)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    Mobile Optimization Issues
                                </li>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1084)">
                                        <path
                                            d="M14.7633 2.06836C16.0347 2.06836 17.0685 3.1004 17.0685 4.37378V15.9009C17.0685 17.1725 16.0347 18.2063 14.7633 18.2063H3.23691C1.96361 18.2063 0.931641 17.1725 0.931641 15.9009V4.37378C0.931641 3.1004 1.96361 2.06836 3.23691 2.06836H14.7633ZM9.74929 5.72822C9.74929 5.29955 9.3999 4.95014 8.9713 4.95014C8.54264 4.95014 8.19324 5.29955 8.19324 5.72822V6.30817C7.97352 6.3586 7.76103 6.39462 7.56287 6.51349C6.91454 6.79087 6.3022 7.31319 6.15812 8.0985C6.00684 8.93057 6.36704 9.67982 7.06943 10.1301C7.58088 10.4579 8.25805 10.6489 8.80199 10.8037C8.88119 10.8254 8.93167 10.847 9.04689 10.8722C9.47555 10.9874 9.92583 11.0775 10.2896 11.3585C10.7111 11.6466 10.5093 12.1582 10.0771 12.3022C9.72412 12.4644 9.26661 12.5184 8.65431 12.4284C8.23649 12.3635 7.81861 12.2194 7.41164 12.0825C7.32156 12.0537 7.23151 12.0213 7.14146 11.9925C6.73444 11.8556 6.29139 12.0789 6.15812 12.4859C6.02125 12.893 6.24457 13.3361 6.6516 13.4694C7.15948 13.6351 7.67093 13.8044 8.19324 13.9196V14.5464C8.19324 14.9751 8.54264 15.3245 8.9713 15.3245C9.3999 15.3245 9.74929 14.9751 9.74929 14.5464V13.9989C10.0699 13.9629 10.376 13.8909 10.657 13.7756C11.3342 13.5054 11.8925 12.9759 12.0438 12.169C12.1951 11.3332 11.8673 10.5516 11.1685 10.0725C10.6318 9.70506 9.91499 9.50334 9.3531 9.34125C9.27022 9.31962 9.18379 9.29438 9.10091 9.24035C8.69033 9.16115 8.26166 9.04586 7.9051 8.81889C7.45482 8.53074 7.78259 8.11289 8.17885 7.93996C8.62545 7.74908 9.17656 7.70583 9.65202 7.79588C9.90776 7.84629 10.1599 7.91833 10.4121 7.98682C10.4661 8.00483 10.5237 8.01923 10.5778 8.03362C10.992 8.14891 11.4206 7.90755 11.5359 7.49333C11.6511 7.07905 11.4062 6.65038 10.992 6.50269C10.9272 6.5171 10.8587 6.49548 10.7939 6.47747C10.4481 6.38021 10.0987 6.28295 9.74929 6.23973V5.72822Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1084">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.916016)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    Ineffective Brand Presentation
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_challenge-end=== */}

        {/* ===portfolio_detail_2_research-start=== */}
        <section className='portfolio_detail_2_research p-100'>
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-6 align-self-center">
                        <div className="build_web_app-content-wrp">
                            <h3>CMExpertise Solution</h3>
                            <p>To address Kishori Creation's challenges, we developed a modern, user-friendly e-commerce website that enhances brand visibility and engagement. Our solution includes mobile optimization, intuitive navigation, and a visually appealing design that effectively showcases their unique collections, ultimately driving customer satisfaction and increasing sales.</p>
                            <ul>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1066)">
                                        <path
                                            d="M7.71478 6.79039C7.71478 7.75929 6.85098 8.54539 5.78632 8.54539H1.92942C0.86436 8.54539 0.000976562 7.75929 0.000976562 6.79039V3.28039C0.000976562 2.31112 0.86436 1.52539 1.92942 1.52539H5.78632C6.85098 1.52539 7.71478 2.31112 7.71478 3.28039V6.79039ZM7.71478 16.1504C7.71478 17.1193 6.85098 17.9054 5.78632 17.9054H1.92942C0.86436 17.9054 0.000976562 17.1193 0.000976562 16.1504V12.6404C0.000976562 11.6715 0.86436 10.8854 1.92942 10.8854H5.78632C6.85098 10.8854 7.71478 11.6715 7.71478 12.6404V16.1504ZM10.286 3.28039C10.286 2.31112 11.1498 1.52539 12.2145 1.52539H16.0714C17.136 1.52539 17.9998 2.31112 17.9998 3.28039V6.79039C17.9998 7.75929 17.136 8.54539 16.0714 8.54539H12.2145C11.1498 8.54539 10.286 7.75929 10.286 6.79039V3.28039ZM17.9998 16.1504C17.9998 17.1193 17.136 17.9054 16.0714 17.9054H12.2145C11.1498 17.9054 10.286 17.1193 10.286 16.1504V12.6404C10.286 11.6715 11.1498 10.8854 12.2145 10.8854H16.0714C17.136 10.8854 17.9998 11.6715 17.9998 12.6404V16.1504Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1066">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.355469)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    User-Centric Design
                                </li>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1071)">
                                        <path
                                            d="M8.17425 1.07897C8.69809 0.845747 9.30271 0.845747 9.82655 1.07897L17.5112 4.49922C17.81 4.63125 17.9998 4.91901 17.9998 5.20677C17.9998 5.55209 17.81 5.83984 17.5112 5.97526L9.82655 9.39455C9.30271 9.62812 8.69809 9.62812 8.17425 9.39455L0.490672 5.97526C0.192144 5.83984 0.000976562 5.55209 0.000976562 5.20677C0.000976562 4.91901 0.192109 4.63125 0.490672 4.49922L8.17425 1.07897ZM17.5112 8.83259C17.81 8.96456 17.9998 9.25235 17.9998 9.54013C17.9998 9.88544 17.81 10.1732 17.5112 10.3086L9.82655 13.7279C9.30271 13.9614 8.69809 13.9614 8.17425 13.7279L0.490672 10.3086C0.192144 10.1732 0.000976562 9.88544 0.000976562 9.54013C0.000976562 9.25235 0.192109 8.96456 0.490672 8.83259L2.36086 7.99974L7.70319 10.3763C8.52581 10.7453 9.47499 10.7453 10.2976 10.3763L15.641 7.99974L17.5112 8.83259ZM10.2976 14.7097L15.641 12.3331L17.5112 13.1659C17.81 13.2979 17.9998 13.5857 17.9998 13.8735C17.9998 14.2188 17.81 14.5065 17.5112 14.6419L9.82655 18.0612C9.30271 18.2948 8.69809 18.2948 8.17425 18.0612L0.490672 14.6419C0.192144 14.5065 0.000976562 14.2188 0.000976562 13.8735C0.000976562 13.5857 0.192109 13.2979 0.490672 13.1659L2.36086 12.3331L7.70319 14.7097C8.52581 15.0787 9.47499 15.0787 10.2976 14.7097Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1071">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.544678)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    Mobile Responsiveness
                                </li>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1077)">
                                        <path
                                            d="M9.0004 19.3453C4.02962 19.3453 0.000976562 15.1775 0.000976562 10.0349C0.000976562 4.89244 4.02962 0.724609 9.0004 0.724609C13.9712 0.724609 17.9998 4.89244 17.9998 10.0349C17.9998 15.1775 13.9712 19.3453 9.0004 19.3453ZM8.15669 10.0349C8.15669 10.3259 8.29729 10.5987 8.53286 10.7296L11.9076 13.0572C12.2943 13.3554 12.8181 13.2463 13.0466 12.8462C13.3349 12.4462 13.2294 11.9043 12.8427 11.6352L9.84411 9.56945V5.08883C9.84411 4.60513 9.46794 4.21599 8.96879 4.21599C8.53286 4.21599 8.12508 4.60513 8.12508 5.08883L8.15669 10.0349Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1077">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.724609)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    SEO Optimization
                                </li>
                                <li>
                                    <span>
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_941_1084)">
                                        <path
                                            d="M14.7633 2.06836C16.0347 2.06836 17.0685 3.1004 17.0685 4.37378V15.9009C17.0685 17.1725 16.0347 18.2063 14.7633 18.2063H3.23691C1.96361 18.2063 0.931641 17.1725 0.931641 15.9009V4.37378C0.931641 3.1004 1.96361 2.06836 3.23691 2.06836H14.7633ZM9.74929 5.72822C9.74929 5.29955 9.3999 4.95014 8.9713 4.95014C8.54264 4.95014 8.19324 5.29955 8.19324 5.72822V6.30817C7.97352 6.3586 7.76103 6.39462 7.56287 6.51349C6.91454 6.79087 6.3022 7.31319 6.15812 8.0985C6.00684 8.93057 6.36704 9.67982 7.06943 10.1301C7.58088 10.4579 8.25805 10.6489 8.80199 10.8037C8.88119 10.8254 8.93167 10.847 9.04689 10.8722C9.47555 10.9874 9.92583 11.0775 10.2896 11.3585C10.7111 11.6466 10.5093 12.1582 10.0771 12.3022C9.72412 12.4644 9.26661 12.5184 8.65431 12.4284C8.23649 12.3635 7.81861 12.2194 7.41164 12.0825C7.32156 12.0537 7.23151 12.0213 7.14146 11.9925C6.73444 11.8556 6.29139 12.0789 6.15812 12.4859C6.02125 12.893 6.24457 13.3361 6.6516 13.4694C7.15948 13.6351 7.67093 13.8044 8.19324 13.9196V14.5464C8.19324 14.9751 8.54264 15.3245 8.9713 15.3245C9.3999 15.3245 9.74929 14.9751 9.74929 14.5464V13.9989C10.0699 13.9629 10.376 13.8909 10.657 13.7756C11.3342 13.5054 11.8925 12.9759 12.0438 12.169C12.1951 11.3332 11.8673 10.5516 11.1685 10.0725C10.6318 9.70506 9.91499 9.50334 9.3531 9.34125C9.27022 9.31962 9.18379 9.29438 9.10091 9.24035C8.69033 9.16115 8.26166 9.04586 7.9051 8.81889C7.45482 8.53074 7.78259 8.11289 8.17885 7.93996C8.62545 7.74908 9.17656 7.70583 9.65202 7.79588C9.90776 7.84629 10.1599 7.91833 10.4121 7.98682C10.4661 8.00483 10.5237 8.01923 10.5778 8.03362C10.992 8.14891 11.4206 7.90755 11.5359 7.49333C11.6511 7.07905 11.4062 6.65038 10.992 6.50269C10.9272 6.5171 10.8587 6.49548 10.7939 6.47747C10.4481 6.38021 10.0987 6.28295 9.74929 6.23973V5.72822Z"
                                            fill="#040404"
                                        />
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_941_1084">
                                            <rect
                                            width="17.9988"
                                            height="18.72"
                                            fill="white"
                                            transform="translate(0.000976562 0.916016)"
                                            />
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    </span>
                                    E-Commerce Integration
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='portfolio_detail_2_research_img'>
                            <img src={portfoliodetail2researchimg} alt={portfoliodetail2researchimg} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_research-end=== */}

        {/* ===portfolio_detail_2_scope_work-start=== */}
        <section className='portfolio_detail_2_scope_work p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Design Thinking Process</h3>
                            <p>The Design Thinking Process is a human-centered approach to innovation that emphasizes empathy and collaboration. It consists of five stages: Empathize, Define, Ideate, Prototype, and Test. By understanding users' needs and challenges, teams can brainstorm creative solutions, build prototypes for feedback, and refine their ideas, resulting in products and services that truly resonate with users and address their problems effectively.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_scope_work-ebd=== */}

        {/* ===portfolio_detail_2_problem-start=== */}
        <section className='portfolio_detail_2_problem p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Results and Impact</h3>
                            <p>The results of implementing our solutions for Kishori Creation were transformative. The new website significantly increased user engagement and satisfaction, leading to a 30% boost in online sales. Improved search engine rankings enhanced visibility, while positive customer feedback highlighted the effective storytelling of their brand’s heritage and values, strengthening customer loyalty.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='problem-img-wrp'>
                                    <img src={problemimg1} alt={problemimg1}/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='problem-img-wrp'>
                                    <img src={problemimg2} alt={problemimg2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* ===portfolio_detail_2_problem-end=== */}

        {/* ===portfolio_detail_2_solution-start=== */}
        <section className='portfolio_detail_2_solution p-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-sm-5 mb-3'>
                            <h3>Future Enhancements</h3>
                            <p>Future enhancements for Kishori Creation's website include integrating advanced analytics to track user behavior, enabling data-driven improvements. Additionally, implementing personalized shopping experiences and expanding social media integration will enhance customer engagement. Lastly, exploring augmented reality features for virtual try-ons can further elevate the online shopping experience and drive sales growth.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='col-12'>
                
                <KishoriSolSlider/>
            </div>
        </section>
        {/* ===portfolio_detail_2_solution-end=== */}

        <Testimonial />
        <LocationSlider />
    </>
  )
}

export default Kishoricreation_Detail