import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import featurebanner from "../images/features-banner-img.png";
import { MdOutlineArrowOutward } from "react-icons/md";
import weprovideimg from "../images/we_provide_img.png";
import { Testimonial } from "./component/Testimonial";
import LocationSlider from "./LocationSlider";
import { BrandSlider } from "./component/BrandSlider";

const Features = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* // ====feature-banner-start=== */}
      <section className="banner_section features_banner">
        <div className="build_web_app-inner">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="build_web_app-content-wrp">
                <h3>
                  Each of our small bags is filled with big features to help you
                  grow at each step
                </h3>
                <p>
                  A Simple Yet Powerful eCommerce Website Builder. Launchestore
                  makes it easy to build the perfect website for your business.
                </p>
                <p>
                  Thousands of Leading eCommerce Companies Trust Us. Create Your
                  Online Store With Our Powerful & Intuitive Website Builder
                  best B2B eCommerce platforms in India
                </p>
                <div
                  className="banner_btn"
                  onClick={() => {
                    navigate("/portfolio");
                  }}
                >
                  <button className="cmn-btn">
                    <span className="cmn-btn-text1">
                      View Portfolio{" "}
                      <MdOutlineArrowOutward className="button-icon" />
                    </span>
                    <span className="cmn-btn-text2">
                      View Portfolio{" "}
                      <MdOutlineArrowOutward className="button-icon" />
                    </span>
                  </button>
                  <button className="cmn-btn play-reel-btn">
                    <span className="cmn-btn-text1">Explore Templates</span>
                    <span className="cmn-btn-text2">Explore Templates</span>
                  </button>
                </div>
                <p className="launch-store-text">
                  Launch your <span>e-commerce</span> store today
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="build_web_app-image-wrp build-web-image-wrp">
                <img src={featurebanner} alt="bulid_website" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // ====feature-banner-end=== */}

      <section className="feature_brand_slider">
        <BrandSlider />
      </section>

      {/* // ===Feature-detail-start=== */}
      <section className="feature_detail p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title feature_detail_title">
                <h2>Launchestore Powerful E-commerce Platform For Growth</h2>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>No, IT Cost</h3>
                  <p>
                    Thousands of Leading eCommerce Companies Trust Us. You don't
                    have to spend money on your own IT infrastructure while
                    using our cloud platform. Simply register and launch your
                    store.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>Easy Store Management</h3>
                  <p>
                    Now you can quickly manage your products through the
                    dashboard of an e-commerce application and complete tasks
                    faster through your e-commerce store whenever you want.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>SEO Friendly URL</h3>
                  <p>
                    Increase traffic with editable robots.txt files and
                    customizable, SEO-friendly URLs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>Integrated Logistics</h3>
                  <p>
                    Agility and speed to increase and safeguard sales. With our
                    Online Store App there are no restrictions, friction, or
                    proprietary barriers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>Your Own Brand Websites</h3>
                  <p>
                    Launchestore makes it simple to build a website that will
                    highlight your brand and give your customers a fantastic
                    experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>Multi Branches Platform</h3>
                  <p>
                    Simplify your operations from a single account, regardless
                    of whether you manage many brands, sell to diverse customer
                    categories, or build across various regions
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>Ready to Launch Mobile Applications</h3>
                  <p>
                    Start your brand new eCommerce store and impress your
                    customers with Launchestore. Our platform creates countless
                    e-commerce opportunities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="feature_detail_card">
                <div className="feature_detail_card_icon">
                  <svg
                    width="42"
                    height="36"
                    viewBox="0 0 42 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M39.75 5.5V33.625H2.25V2.375H36.625C37.4538 2.375 38.2487 2.70424 38.8347 3.29029C39.4208 3.87634 39.75 4.6712 39.75 5.5Z"
                      fill="white"
                    />
                    <path
                      d="M41.3125 33.625C41.3125 34.0394 41.1479 34.4368 40.8549 34.7299C40.5618 35.0229 40.1644 35.1875 39.75 35.1875H2.25C1.8356 35.1875 1.43817 35.0229 1.14515 34.7299C0.85212 34.4368 0.6875 34.0394 0.6875 33.625V2.375C0.6875 1.9606 0.85212 1.56317 1.14515 1.27015C1.43817 0.97712 1.8356 0.8125 2.25 0.8125C2.6644 0.8125 3.06183 0.97712 3.35485 1.27015C3.64788 1.56317 3.8125 1.9606 3.8125 2.375V23.6035L13.6445 13.7695C13.7896 13.6243 13.962 13.509 14.1517 13.4304C14.3413 13.3517 14.5447 13.3113 14.75 13.3113C14.9553 13.3113 15.1587 13.3517 15.3483 13.4304C15.538 13.509 15.7104 13.6243 15.8555 13.7695L21 18.916L31.291 8.625H27.25C26.8356 8.625 26.4382 8.46038 26.1451 8.16735C25.8521 7.87433 25.6875 7.4769 25.6875 7.0625C25.6875 6.6481 25.8521 6.25067 26.1451 5.95765C26.4382 5.66462 26.8356 5.5 27.25 5.5H35.0625C35.4769 5.5 35.8743 5.66462 36.1674 5.95765C36.4604 6.25067 36.625 6.6481 36.625 7.0625V14.875C36.625 15.2894 36.4604 15.6868 36.1674 15.9799C35.8743 16.2729 35.4769 16.4375 35.0625 16.4375C34.6481 16.4375 34.2507 16.2729 33.9576 15.9799C33.6646 15.6868 33.5 15.2894 33.5 14.875V10.834L22.1055 22.2305C21.9604 22.3757 21.788 22.491 21.5983 22.5696C21.4087 22.6483 21.2053 22.6887 21 22.6887C20.7947 22.6887 20.5913 22.6483 20.4017 22.5696C20.212 22.491 20.0396 22.3757 19.8945 22.2305L14.75 17.084L3.8125 28.0215V32.0625H39.75C40.1644 32.0625 40.5618 32.2271 40.8549 32.5201C41.1479 32.8132 41.3125 33.2106 41.3125 33.625Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="feature_detail_card_content">
                  <h3>Integrated Payment Gateway</h3>
                  <p>
                    Quick, effective, end-to-end payments. With our integrated
                    payment processing system, you can easily and securely
                    accept credit cards and other payment methods.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // ===Feature-detail-end=== */}

      {/* <!-- ===We-Provide-start=== --> */}
      <section className="we_provide feauters_we_provide p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="we_provide_title">
                <h2>
                Launch Your, Successful Store
                </h2>
              </div>
            </div>
            <div className="col-12">
              <div className="we_provide_image-wrp">
                <img src={weprovideimg} alt="What_we_provide" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content1">
                <h3>Percentage Discount</h3>
                <p>
                  Launch e Store Offer some percentage discount using a coupon
                  code.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content2">
                <h3>Flat Discount </h3>
                <p>
                  Launch e Store Discount Offer a flat discount on a minimum
                  purchase.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content3">
                <h3>Percentage Discount</h3>
                <p>
                  Launch e Store Offer some percentage discount using a coupon
                  code.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===We-Provide-end=== --> */}

      <Testimonial />
      <LocationSlider />
    </>
  );
};

export default Features;
