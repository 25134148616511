import React from 'react'
import Slider from "react-slick";
import brandimg1 from "../../images/brand-img-1.png";
import brandimg2 from "../../images/brand-img-2.png";
import brandimg3 from "../../images/brand-img-3.png";
import brandimg4 from "../../images/brand-img-4.png";
import brandimg5 from "../../images/brand-img-5.png";
import brandimg6 from "../../images/brand-img-6.png";
import brandimg7 from "../../images/brand-img-7.png";

export const BrandSlider = () => {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 7,
            },
          },
        ],
      };
  return (
    <div className="banner-brand-slider">
        <div className="owl-carousel owl-theme brand-slider">
            <Slider {...settings}>
            <div className="banner-brand-slider-img">
                <img src={brandimg1} alt="brand-img" />
            </div>
            <div className="banner-brand-slider-img">
                <img src={brandimg2} alt="brand-img" />
            </div>
            <div className="banner-brand-slider-img">
                <img src={brandimg3} alt="brand-img" />
            </div>
            <div className="banner-brand-slider-img">
                <img src={brandimg4} alt="brand-img" />
            </div>
            <div className="banner-brand-slider-img">
                <img src={brandimg5} alt="brand-img" />
            </div>
            <div className="banner-brand-slider-img">
                <img src={brandimg6} alt="brand-img" />
            </div>
            <div className="banner-brand-slider-img">
                <img src={brandimg7} alt="brand-img" />
            </div>
            </Slider>
        </div>
    </div>
  )
}
