import React, { useEffect } from "react";
import overviewappimg from '../images/zumkha-overview.png'
import researchmainimg from '../images/research_main_img.png'
import researchzumkhalogo from '../images/research_zumkha_logo.png'
import researchzumkhaimg from '../images/research_zumkha_img.png'
import scopeworkimg1 from '../images/zumkha1-scop1.png'
import scopeworkimg2 from '../images/zumkha2-scop2.png'
import problemimg1 from '../images/zumkha-result1.png'
import problemimg2 from '../images/zumkha-result2.png'
import brandingimgleft from '../images/Outdoor_Sign_Mockup_1.png'
import brandingimgright1 from '../images/free_box_mockup_1.png'
import brandingimgright2 from '../images/branding_img_right2.png'
import wireframingimg1 from '../images/wireframing_img1.png'
import wireframingimg2 from '../images/wireframing_img2.png'
import webviewimg1 from '../images/webview-img1.png'
import webviewimg2 from '../images/Frame_663.png'
import webviewimg3 from '../images/Frame_66a4.png'
import moblieviewimg1 from '../images/mobileviewz.png'
import moblieviewimg2 from '../images/moblieview_img2.png'
import moblieviewimg3 from '../images/moblieview_img3.png'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'

const ZumkhaDetail_1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <section className='portfolio_detail_banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 position-relative'>
                        <div className='portfolio_detail_banner_content'>
                            <h6>Jewellery Store</h6>
                            <h2>Zumkha - Elegance in Every Piece</h2>
                            <ul>
                                <li>Luxury</li>
                                <li>Elegance</li>
                                <li>Jewellery</li>
                                <li>Craftsmanship</li>
                                <li>Design</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='overview_app p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='overview_app_title'>
                            <h2>Zumkha: Timeless <br/>Craftsmanship</h2>
                            <p>At Zumkha, we believe in showcasing the beauty of craftsmanship. Our exquisite jewellery pieces reflect elegance and sophistication, designed to elevate any occasion. Each item is thoughtfully created to offer a unique blend of style, quality, and timeless appeal. Discover your elegance with Zumkha.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='overview_app_image_wrp'>
                            <img src={overviewappimg} alt={overviewappimg} />
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Mission</h3>
                            <p>Our mission was to craft an intuitive e-commerce platform for Zumkha, seamlessly showcasing their exquisite jewellery collections. By integrating robust features and user-friendly navigation, we enabled Zumkha to expand their reach globally, offering customers a digital experience that mirrors the brand's commitment to quality, heritage, and customer relationships.</p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Business Problem</h3>
                            <p>Creating an online store for Zumkha required merging visual elegance with advanced functionality. Our challenge was to craft a digital presence as exquisite as their jewelry, building a seamless, responsive shopping experience that highlights Zumkha’s luxury pieces while ensuring intuitive navigation, speed, and secure payment integration.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mt-1 mt-sm-2'>
                            <h3>CMExpertise Solution</h3>
                            <p>To address Zumkha's challenges, we designed a robust e-commerce solution with high-resolution image optimization, flexible product customization options, secure payment integration, and advanced inventory management. This solution offers a seamless shopping experience while ensuring security, speed, and scalability tailored for jewelry e-commerce.</p>
                        </div>
                        <div className='mission-challange-research-img-wrp'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <img src={researchmainimg} alt={researchmainimg} className='research-img-1'/>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='reserach-right-img'>
                                        <div className='research_zumkha_logo_wrp'>
                                            <img src={researchzumkhalogo} alt={researchzumkhalogo} className='research-img-2'/>
                                        </div>
                                        <img src={researchzumkhaimg} alt={researchzumkhaimg} className='research-img-3'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Design Thinking Process</h3>
                            <p>Design Thinking Process is a human-centered innovation method focused on understanding customer needs, rapid prototyping, and creative idea generation. It combines empathy, ideation, and iterative development to solve challenges and deliver user-centric solutions. By applying this approach, we craft unique, impactful e-commerce experiences that align with customer desires and enhance Zumkha's jewelry brand presence in the digital marketplace.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/>
                        </div>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Results and Impact</h3>
                            <p>For Zumkha, our solutions delivered measurable success across key metrics. We achieved a notable increase in online sales and traffic, attracting more customers to the site and elevating the brand's digital footprint. Customer satisfaction ratings reflected positive engagement, validating our approach to creating a seamless, high-quality online jewelry experience.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='problem-img-wrp'>
                                    <img src={problemimg1} alt={problemimg1}/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='problem-img-wrp'>
                                    <img src={problemimg2} alt={problemimg2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-5'>
                            <h3>Future Enhancements</h3>
                            <p>Looking ahead, Zumkha aims to continuously evolve its e-commerce experience. Future plans include implementing advanced features like AI-powered personalization to recommend products based on user preferences and expanding to international markets. Enhanced customer engagement tools, improved mobile accessibility, and streamlined checkout processes will support long-term growth, keeping Zumkha at the forefront of digital luxury retail experiences.</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 col-sm-4'>
                                <div className='solution_left_menu'>
                                    <ul>
                                        <li>Branding</li>
                                        <li>Wireframing</li>
                                        <li>Web View</li>
                                        <li>Mobile View</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-9 col-sm-8'>
                                <div className='solution_right_content'>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Branding</h3>
                                            <p>Zumkha’s branding combines timeless elegance with modern luxury. From the logo to the color palette, every visual element was crafted to reflect the sophistication of the jewelry while maintaining a strong, recognizable identity.</p>
                                        </div>
                                        <div className='branding_img_Wrp'>
                                            <div className='row'>
                                                <div className="col-lg-7">
                                                    <div className='branding_img_left'>
                                                        <img src={brandingimgleft} alt={brandingimgleft} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className='branding_img_right'>
                                                        <img src={brandingimgright1} alt={brandingimgright1} />
                                                        <img src={brandingimgright2} alt={brandingimgright2} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Wireframing</h3>
                                            <p>The wireframing process for Zumkha focused on creating a clear, intuitive user journey. The layout ensures smooth navigation while keeping the website visually appealing, with well-structured pages that prioritize user experience.</p>
                                        </div>
                                        <div className='wireframing_img_wrp'>
                                            <img src={wireframingimg1} alt={wireframingimg1} className='wireframingimg1'/>
                                            <img src={wireframingimg2} alt={wireframingimg2} className='wireframingimg2'/>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Web View</h3>
                                            <p>The Zumkha website is designed for an optimal desktop experience. Its clean, polished design emphasizes the beauty of the jewelry, while intuitive navigation and fast loading times ensure a seamless online shopping experience.</p>
                                        </div>
                                        <div className='webview_img_wrp'>
                                            <img src={webviewimg1} alt={webviewimg1} />
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='webview_img_wrp2 mb-4'>
                                                    <img src={webviewimg2} alt={webviewimg2} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='webview_img_wrp2'>
                                                    <img src={webviewimg3} alt={webviewimg3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp mb-4'>
                                            <h3>Mobile View</h3>
                                            <p>Zumkha’s mobile view adapts beautifully to smaller screens, maintaining all the functionality and elegance of the desktop version. With responsive design, customers enjoy a smooth, engaging shopping experience, no matter the device.</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg1} alt={moblieviewimg1} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg2} alt={moblieviewimg2} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg3} alt={moblieviewimg3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <Testimonial />
      <LocationSlider />
    </>
  )
}

export default ZumkhaDetail_1