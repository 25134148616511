import React, { useState } from 'react';
import insightsimg1 from "../images/insights-img-1.png";
import insightsimg2 from "../images/insights-img-2.png";
import insightsimg3 from "../images/insights-img-3.png";
import LocationSlider from './LocationSlider';

const Blog = () => {

    const [activeTab, setActiveTab] = useState('tab1');

    // Function to handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

  return (
    <>
      {/* <!-- ===blog-banner-Start=== --> */}
      <section className="pricing p-100 pricing-banner blog-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner_content">
                <h1>The Launch e Store Blog</h1>
                <h4>Resources, advise and inspiration for creators</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===blog-banner-End=== --> */}

      {/* <!-- ===blog-Start=== --> */}
      <section className='blogs'>
            <div className='blog_tabs'>
                <div className='container'>
                    <div className='blog_tabs_Wrp'>
                        <button
                            className={activeTab === 'tab1' ? 'active' : ''}
                            onClick={() => handleTabChange('tab1')}
                            >
                            All
                        </button>
                        <button
                            className={activeTab === 'tab2' ? 'active' : ''}
                            onClick={() => handleTabChange('tab2')}
                            >
                            Art
                        </button>
                        <button
                            className={activeTab === 'tab3' ? 'active' : ''}
                            onClick={() => handleTabChange('tab3')}
                            >
                            Business
                        </button>
                        <button
                            className={activeTab === 'tab4' ? 'active' : ''}
                            onClick={() => handleTabChange('tab4')}
                            >
                            Design
                        </button>
                        <button
                            className={activeTab === 'tab5' ? 'active' : ''}
                            onClick={() => handleTabChange('tab5')}
                            >
                            Fashion
                        </button>
                        <button
                            className={activeTab === 'tab6' ? 'active' : ''}
                            onClick={() => handleTabChange('tab6')}
                            >
                            Inspiration
                        </button>
                        <button
                            className={activeTab === 'tab7' ? 'active' : ''}
                            onClick={() => handleTabChange('tab7')}
                            >
                            Learn
                        </button>
                        <button
                            className={activeTab === 'tab8' ? 'active' : ''}
                            onClick={() => handleTabChange('tab8')}
                            >
                            Photography
                        </button>
                        <button
                            className={activeTab === 'tab9' ? 'active' : ''}
                            onClick={() => handleTabChange('tab9')}
                            >
                            Resources
                        </button>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="blog_tab_content">
                    <div className='row'>
                        {activeTab === 'tab1' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab2' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab3' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab4' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>

                            </>
                        }
                        {activeTab === 'tab5' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab6' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab7' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab8' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {activeTab === 'tab9' &&
                            <>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg1} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Benefits of Choosing Launchestore as Your Online Store
                                            Builder
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg2} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            Ten things to consider when you wish to start an online
                                            business.
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mb-md-5 mb-4">
                                    <div className="insights_card">
                                        <div className="insights_card_img">
                                        <img src={insightsimg3} alt="blog-image" />
                                        </div>
                                        <div className="insights_card_content">
                                        <div>
                                            <h6>
                                            <span>
                                                blog <i className="fa-solid fa-circle"></i>
                                            </span>{" "}
                                            11 Min Read
                                            </h6>
                                            <h6>August 29,2024</h6>
                                        </div>
                                        <h4>
                                            How to choose the right B2B eCommerce Platform for your
                                            Business?
                                        </h4>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
      </section>
      {/* <!-- ===blog-end=== --> */}

      <LocationSlider />
    </>
  )
}

export default Blog