import React from 'react'
import Slider from "react-slick";
import brandingimgleft from '../../images/laxmirajB.png'
import brandingimgright1 from '../../images/laxmirajB2.png'
import brandingimgright2 from '../../images/laxmirajB3.png'
import solutionwireframslider from '../../images/solution_wirefram_slider.png'
import webviewsliderimg1 from '../../images/laxmirajW1.png'
import webviewimg2 from '../../images/laxmirajW2.png'
import webviewimg3 from '../../images/laxmirajW3.png'
import moblieviewimg1 from '../../images/laxmirajmv1.png'
import moblieviewimg2 from '../../images/laxmirajmv2.png'
import moblieviewimg3 from '../../images/laxmirajmv3.png'

export const LaxmirajSolutionSlider = () => {
    var settings = {
        dots: false,
        nav:false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 2000,
        arrows:false,
        centerMode: true,
        responsive: [
          {
            breakpoint: 320,
            settings: {
                slidesToShow: 1,
                centerMode: false,
            },
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 1,
              centerMode: false,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              centerMode: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerMode: false,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },    
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 10000,
            settings: {
              slidesToShow: 2.5,
            },
          },
        ],
      };
  return (
    <div className="solution-slider-wrp">
        <div className="owl-carousel owl-theme solution-slider">
            <Slider {...settings}>
                <div className='solution-slider-main'>
                    <div className='branding_img_Wrp'>
                        <div className='row'>
                            <div className="col-sm-7">
                                <div className='branding_img_left'>
                                    <img src={brandingimgleft} alt={brandingimgleft} />
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className='branding_img_right'>
                                    <img src={brandingimgright1} alt={brandingimgright1} />
                                    <img src={brandingimgright2} alt={brandingimgright2} />
                                </div>
                            </div>
                        </div>
                        <h3>Branding</h3>                        
                    </div>
                </div>
                <div className='solution-slider-main'>
                    <div className='branding_img_Wrp'>
                        <div className='wirframe_slider_image'>
                            <img src={solutionwireframslider} alt={solutionwireframslider} />
                        </div>
                        <h3>Wireframing</h3>                        
                    </div>
                </div>
                <div className='solution-slider-main'>
                    <div className='branding_img_Wrp'>
                        <div className='row'>
                            <div className="col-sm-5">
                                <div className='branding_img_right'>
                                    <img src={webviewimg2} alt={webviewimg2} />
                                    <img src={webviewimg3} alt={webviewimg3} />
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className='branding_img_left'>
                                    <img src={webviewsliderimg1} alt={webviewsliderimg1} />
                                </div>
                            </div>   
                        </div>
                        <h3>Web View</h3>                        
                    </div>
                </div>
                <div className='solution-slider-main'>
                    <div className='branding_img_Wrp'>
                        <div className='row'>
                            <div className="col-sm-7">
                                <div className='branding_img_left'>
                                    <img src={moblieviewimg1} alt={moblieviewimg1} />
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className='branding_img_right'>
                                    <img src={moblieviewimg2} alt={moblieviewimg2} />
                                    <img src={moblieviewimg3} alt={moblieviewimg3} />
                                </div>
                            </div>
                        </div>
                        <h3>Mobile View</h3>                        
                    </div>
                </div>
            </Slider>
        </div>
    </div>
  )
}
