import React, { useEffect } from "react";
import overviewappimg from '../images/dpdecor-mokuf.png'
import researchmainimg from '../images/reseech-decor1.png'
import researchzumkhalogo from '../images/reseech-decor2.png'
import researchzumkhaimg from '../images/reseech-decor3.png'
import scopeworkimg1 from '../images/decor-scop-work.png'
import scopeworkimg2 from '../images/decor-scop-work2.png'
import problemimg1 from '../images/decor-problem1.png'
import problemimg2 from '../images/decor-problem2.png'
import brandingimgleft from '../images/solution-decor1.png'
import brandingimgright1 from '../images/solution-decor2.png'
import brandingimgright2 from '../images/solution-decor3.png'
import wireframingimg1 from '../images/wireframing_img1.png'
import wireframingimg2 from '../images/wireframing_img2.png'
import webviewimg1 from '../images/web-view.png'
import webviewimg2 from '../images/Frame_663.png'
import webviewimg3 from '../images/web-view1.png'
import moblieviewimg1 from '../images/moile-viewdecor1.png'
import moblieviewimg2 from '../images/moile-viewdecor2.png'
import moblieviewimg3 from '../images/moile-viewdecor3.png'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'

const DPDecor_Detail_backup = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <section className='portfolio_detail_banner dpdecor-detail-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 position-relative'>
                        <div className='portfolio_detail_banner_content'>
                            <h6>Manufacturer Store</h6>
                            <h2>DP Decor: Innovative Spaces</h2>
                            <ul>
                                <li>Innovative</li>
                                <li>Personalized</li>
                                <li>High-end</li>
                                <li>Space Planning</li>
                                <li>Client-focused</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='overview_app dedecor-mokuf p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='overview_app_title'>
                            <h2>Inspired Interiors,<br/> Perfect Spaces</h2>
                            <p>DP Decor is a premier interior design and space planning firm specializing in high-end residential and commercial projects. With a commitment to innovation, we prioritize client involvement throughout the design process, ensuring that every project reflects individual tastes, needs, and budgets while delivering exceptional value and personalized attention.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='overview_app_image_wrp'>
                            <img src={overviewappimg} alt={overviewappimg} />
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Mission</h3>
                            <p>Our mission is to empower DP Decor with a cutting-edge eCommerce solution that enhances their ability to showcase and sell high-end interior design products. We strive to deliver an intuitive platform that streamlines their operations, elevates customer experience, and drives business growth while reflecting the essence of their brand.</p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Business Problem</h3>
                            <p>DP Decor faced challenges in effectively reaching their target market and managing product sales. Their existing systems were outdated, limiting online visibility and customer engagement. To thrive in the competitive interior design industry, they required a modern eCommerce solution that streamlined operations and enhanced their overall customer experience.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mt-1 mt-sm-2'>
                            <h3>CMExpertise Solution</h3>
                            <p>We provided DP Decor with a comprehensive eCommerce platform designed to enhance their online presence. This solution included user-friendly navigation, advanced inventory management, and secure payment processing. By integrating these features, we empowered DP Decor to streamline operations and significantly improve customer engagement and satisfaction.</p>
                        </div>
                        <div className='mission-challange-research-img-wrp'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <img src={researchmainimg} alt={researchmainimg} className='research-img-1'/>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='reserach-right-img'>
                                        <div className='research_zumkha_logo_wrp'>
                                            <img src={researchzumkhalogo} alt={researchzumkhalogo} className='research-img-2'/>
                                        </div>
                                        <img src={researchzumkhaimg} alt={researchzumkhaimg} className='research-img-3'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Design Thinking Process</h3>
                            <p>The Design Thinking process is a human-centered approach to problem-solving that emphasizes understanding user needs. It consists of five key stages: Empathize, Define, Ideate, Prototype, and Test. By iterating through these stages, teams can develop innovative solutions that effectively address user challenges, promote collaboration, and drive meaningful results in product development and service design.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/>
                        </div>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Results and Impact</h3>
                            <p>The implementation of our eCommerce solution for DP Decor significantly boosted their online sales and customer engagement. With improved user experience and streamlined operations, they achieved a 30% increase in sales within six months. This transformation has positioned DP Decor as a leader in the competitive interior design market.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='problem-img-wrp'>
                                    <img src={problemimg1} alt={problemimg1}/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='problem-img-wrp'>
                                    <img src={problemimg2} alt={problemimg2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-5'>
                            <h3>Future Enhancements</h3>
                            <p>To further elevate DP Decor's eCommerce platform, we plan to integrate advanced analytics for better customer insights, enhance mobile optimization for increased accessibility, and implement personalized marketing strategies. These enhancements will not only improve user experience but also drive higher conversion rates and foster stronger customer loyalty in the future.</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 col-sm-4'>
                                <div className='solution_left_menu'>
                                    <ul>
                                        <li>Branding</li>
                                        <li>Wireframing</li>
                                        <li>Web View</li>
                                        <li>Mobile View</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-9 col-sm-8'>
                                <div className='solution_right_content'>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Branding</h3>
                                            <p>Our team created a cohesive branding strategy for DP Decor that accentuates their identity as a luxury interior design firm. This includes logo design, color palette selection, and visual elements tailored to reflect elegance, sophistication, and a high-end aesthetic that appeals to their discerning clientele.</p>
                                        </div>
                                        <div className='branding_img_Wrp'>
                                            <div className='row'>
                                                <div className="col-lg-7">
                                                    <div className='branding_img_left'>
                                                        <img src={brandingimgleft} alt={brandingimgleft} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className='branding_img_right'>
                                                        <img src={brandingimgright1} alt={brandingimgright1} />
                                                        <img src={brandingimgright2} alt={brandingimgright2} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Wireframing</h3>
                                            <p>For DP Decor, we developed comprehensive wireframes that map out the website’s structure, enabling a smooth user experience and optimal navigation flow. Each element is positioned for maximum engagement, ensuring the design aligns with the firm’s luxurious and client-centered values.</p>
                                        </div>
                                        <div className='wireframing_img_wrp'>
                                            <img src={wireframingimg1} alt={wireframingimg1} className='wireframingimg1'/>
                                            <img src={wireframingimg2} alt={wireframingimg2} className='wireframingimg2'/>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Web View</h3>
                                            <p>We crafted a responsive, visually rich web layout for DP Decor, prioritizing functionality and design balance. High-resolution images, smooth scrolling, and strategic CTAs allow users to explore services and portfolio sections seamlessly, reflecting the company’s premium interior design ethos.</p>
                                        </div>
                                        <div className='webview_img_wrp'>
                                            <img src={webviewimg1} alt={webviewimg1} />
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='webview_img_wrp2 mb-4'>
                                                    <img src={webviewimg2} alt={webviewimg2} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='webview_img_wrp2'>
                                                    <img src={webviewimg3} alt={webviewimg3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp mb-4'>
                                            <h3>Mobile View</h3>
                                            <p>DP Decor’s mobile interface was designed for on-the-go accessibility, mirroring the elegance of the desktop site. Optimized layouts and touch-friendly navigation ensure the mobile experience is as engaging and functional as the web view, enhancing user interaction.</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg1} alt={moblieviewimg1} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg2} alt={moblieviewimg2} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg3} alt={moblieviewimg3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <Testimonial />
      <LocationSlider />
    </>
  )
}

export default DPDecor_Detail_backup