import React from 'react'
import overviewappimg from '../images/overview_app_img.png'
import researchmainimg from '../images/research_main_img.png'
import researchzumkhalogo from '../images/research_zumkha_logo.png'
import researchzumkhaimg from '../images/research_zumkha_img.png'
import scopeworkimg1 from '../images/scope_work_img1.png'
import scopeworkimg2 from '../images/scope_work_img2.png'
import problemimg1 from '../images/problem_img1.png'
import problemimg2 from '../images/problem_img2.png'
import brandingimgleft from '../images/branding_img_left.png'
import brandingimgright1 from '../images/branding_img_right1.png'
import brandingimgright2 from '../images/branding_img_right2.png'
import wireframingimg1 from '../images/wireframing_img1.png'
import wireframingimg2 from '../images/wireframing_img2.png'
import webviewimg1 from '../images/webview-img1.png'
import webviewimg2 from '../images/webview-img2.png'
import webviewimg3 from '../images/webview-img3.png'
import moblieviewimg1 from '../images/moblieview_img1.png'
import moblieviewimg2 from '../images/moblieview_img2.png'
import moblieviewimg3 from '../images/moblieview_img3.png'
import { Testimonial } from './component/Testimonial'
import LocationSlider from './LocationSlider'

const PortfolioDetail = () => {
  return (
    <>
        <section className='portfolio_detail_banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 position-relative'>
                        <div className='portfolio_detail_banner_content'>
                            <h6>Jewellery Store</h6>
                            <h2>Zumkha - Luxury in Every Detail</h2>
                            <ul>
                                <li>Application</li>
                                <li>Website</li>
                                <li>eCommerce</li>
                                <li>Silver Jewellery</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='overview_app p-100 pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='overview_app_title'>
                            <h2>Overview of <br/> The Unibest- App</h2>
                            <p>Unibest is a <u>best clothing selling app</u> of uniforms, textiles, linen, safety items, and promotional items. Its in-house operations, including design, fabric, sample development, production, quality control, packing, and logistics, help us match client needs on time.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='overview_app_image_wrp'>
                            <img src={overviewappimg} alt={overviewappimg} />
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Mission</h3>
                            <p>The mission of the clothing eCommerce platform is to provide a seamless shopping experience by offering accurate sizing tools, reducing returns, and enhancing customer satisfaction. It aims to simplify the checkout process to decrease cart abandonm-ent while building trust through high-quality product representation. </p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='mission-challange-research-wrp'>
                            <h3>The Challenge</h3>
                            <p>A key challenge in clothing eCommerce is addressing sizing and fit issues, which often lead to high return rates and customer dissatisfaction. Additionally, many retailers face high cart abandonment due to complex checkout processes or doubts about product quality. Effective inventory management is also critical.</p>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mt-1 mt-sm-2'>
                            <h3>Research</h3>
                            <p>The research focused on understanding user behavior, particularly the challenges they face with sizing and fit when shopping online. Surveys and user interviews revealed high rates of cart abandonment due to complex checkout processes and uncertainty about product quality.</p>
                        </div>
                        <div className='mission-challange-research-img-wrp'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <img src={researchmainimg} alt={researchmainimg} className='research-img-1'/>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='reserach-right-img'>
                                        <div className='research_zumkha_logo_wrp'>
                                            <img src={researchzumkhalogo} alt={researchzumkhalogo} className='research-img-2'/>
                                        </div>
                                        <img src={researchzumkhaimg} alt={researchzumkhaimg} className='research-img-3'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Scope of Work</h3>
                            <p>The scope of work for the clothing eCommerce project includes designing an intuitive user interface that simplifies product discovery and checkout, integrating accurate sizing tools to reduce returns, and improving product presentation to build customer trust. It also involves implementing an efficient inventory management system, optimizing the mobile shopping experience, and conducting usability testing to ensure a seamless user journey from browsing to purchase.</p>
                        </div>
                        <div className='scope_work_img_wrp'>
                            <img src={scopeworkimg1} alt={scopeworkimg1} className='scopeworkimg1'/>
                            <img src={scopeworkimg2} alt={scopeworkimg2} className='scopeworkimg2'/>
                        </div>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className='mission-challange-research-wrp'>
                            <h3>Problem</h3>
                            <p>The main problem for the clothing eCommerce platform is the high return rate due to sizing and fit issues, which impacts customer satisfaction and operational costs. Additionally, cart abandonment is a major concern, with many users not completing their purchases due to complex checkout processes and doubts about product quality. Inefficient inventory management also leads to frequent stockouts or overstocking, affecting sales and customer experience.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='problem-img-wrp'>
                                    <img src={problemimg1} alt={problemimg1}/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='problem-img-wrp'>
                                    <img src={problemimg2} alt={problemimg2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mission-challange-research-wrp mb-5'>
                            <h3>Solution</h3>
                            <p>The solution involves implementing a user-friendly interface with enhanced sizing tools to help customers choose the right fit, reducing return rates. Simplifying the checkout process and providing clear product information and visuals will build trust and lower cart abandonment. Additionally, an optimized inventory management system will ensure better stock control, reducing stockouts and overstock, leading to a smoother shopping experience and increased sales.</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 col-sm-4'>
                                <div className='solution_left_menu'>
                                    <ul>
                                        <li>Branding</li>
                                        <li>Wireframing</li>
                                        <li>Web View</li>
                                        <li>Mobile View</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-9 col-sm-8'>
                                <div className='solution_right_content'>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Branding</h3>
                                            <p>The solution involves implementing a user-friendly interface with enhanced sizing tools to help customers choose the right fit, reducing return rates. Simplifying the checkout process and providing clear product information and visuals will build trust and lower cart abandonment.</p>
                                        </div>
                                        <div className='branding_img_Wrp'>
                                            <div className='row'>
                                                <div className="col-lg-7">
                                                    <div className='branding_img_left'>
                                                        <img src={brandingimgleft} alt={brandingimgleft} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className='branding_img_right'>
                                                        <img src={brandingimgright1} alt={brandingimgright1} />
                                                        <img src={brandingimgright2} alt={brandingimgright2} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Wireframing</h3>
                                            <p>The solution involves implementing a user-friendly interface with enhanced sizing tools to help customers choose the right fit, reducing return rates. Simplifying the checkout process and providing clear product information and visuals will build trust and lower cart abandonment.</p>
                                        </div>
                                        <div className='wireframing_img_wrp'>
                                            <img src={wireframingimg1} alt={wireframingimg1} className='wireframingimg1'/>
                                            <img src={wireframingimg2} alt={wireframingimg2} className='wireframingimg2'/>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp'>
                                            <h3>Web View</h3>
                                            <p>The solution involves implementing a user-friendly interface with enhanced sizing tools to help customers choose the right fit, reducing return rates. Simplifying the checkout process and providing clear product information and visuals will build trust and lower cart abandonment.</p>
                                        </div>
                                        <div className='webview_img_wrp'>
                                            <img src={webviewimg1} alt={webviewimg1} />
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='webview_img_wrp2 mb-4'>
                                                    <img src={webviewimg2} alt={webviewimg2} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='webview_img_wrp2'>
                                                    <img src={webviewimg3} alt={webviewimg3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='solution_right_content_inner'>
                                        <div className='mission-challange-research-wrp mb-4'>
                                            <h3>Mobile View</h3>
                                            <p>The solution involves implementing a user-friendly interface with enhanced sizing tools to help customers choose the right fit, reducing return rates. Simplifying the checkout process and providing clear product information and visuals will build trust and lower cart abandonment.</p>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg1} alt={moblieviewimg1} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg2} alt={moblieviewimg2} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='mobileview_img_wrp'>
                                                    <img src={moblieviewimg3} alt={moblieviewimg3} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <Testimonial />
      <LocationSlider />
    </>
  )
}

export default PortfolioDetail