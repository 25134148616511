import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import toolssliderimg1 from "../images/tools-slider-img1.png";
import toolssliderimg2 from "../images/tools-slider-img2.png";
import toolssliderimg3 from "../images/tools-slider-img3.png";
import ourvaluesimg from "../images/our-values-img.png";

// import testimonialclient2 from '../images/testimonial_client_2.png'
// import testimonialclient3 from '../images/testimonial_client_3.png'
import { MdOutlineArrowOutward } from "react-icons/md";
import LocationSlider from "./LocationSlider";
import { Testimonial } from "./component/Testimonial";
import { WebsiteStep } from "./component/WebsiteStep";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  centerMode: true,
  responsive: [
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- ===About-Banner-Start=== --> */}
      <section className="banner_section about_banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner_main_wrp">
                <div className="banner_content">
                  <h1>Simplifying e-commerce for businesses of all sizes.</h1>
                  <div className="banner_btn mt-md-5 mt-4">
                    <button
                      className="cmn-btn"
                      onClick={() => {
                        navigate("/contact");
                      }}
                    >
                      <span className="cmn-btn-text1">
                        Contact Us <MdOutlineArrowOutward className="button-icon" />
                      </span>
                      <span className="cmn-btn-text2">
                        Contact Us <MdOutlineArrowOutward className="button-icon" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="banner-tools-slider">
            <div className="owl-carousel owl-theme tools-slider">
              <Slider {...settings}>
                <div className="banner_tools_slider_img">
                  <img src={toolssliderimg1} alt="tools-img" />
                </div>
                <div className="banner_tools_slider_img">
                  <img src={toolssliderimg2} alt="tools-img" />
                </div>
                <div className="banner_tools_slider_img">
                  <img src={toolssliderimg3} alt="tools-img" />
                </div>
                <div className="banner_tools_slider_img">
                  <img src={toolssliderimg1} alt="tools-img" />
                </div>
                <div className="banner_tools_slider_img">
                  <img src={toolssliderimg2} alt="tools-img" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===About-Banner-End=== --> */}

      {/* <!-- ===About-Content-Start=== --> */}
      <section className="about-content-section p-100 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content-wrp">
                <h3>
                  LaunchEstore simplifies eCommerce for businesses, offering
                  efficient tools to sell and grow online.
                </h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content-wrp">
                <p>
                  At LaunchEstore, we’re passionate about helping businesses
                  succeed online. Our platform offers everything you need to
                  create a professional e-commerce website, manage products,
                  process payments, and track orders—all in one place. Whether
                  you’re a small business or an established brand, we’ve got the
                  tools to help you grow and scale efficiently.
                </p>
                <p>
                  LaunchEstore empowers businesses of all sizes to build an
                  online presence with ease. Our platform offers intuitive
                  features to design, manage, and scale your store. From setup
                  to sales, we make growing your online business seamless and
                  efficient.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===About-Content-End=== --> */}

      {/* <!-- ===We-Provide-start=== --> */}
      <section className="we_provide our_values p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="we_provide_title">
                <h2>
                  Launch Your,
                  <br />
                  Successful Store
                </h2>
                <button
                  className="cmn-btn"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  <span className="cmn-btn-text1">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />
                  </span>
                  <span className="cmn-btn-text2">
                    Sign Up <MdOutlineArrowOutward className="button-icon" />{" "}
                  </span>
                </button>
              </div>
            </div>
            <div className="col-12">
              <div className="we_provide_image-wrp">
                <img src={ourvaluesimg} alt="What_we_provide" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content1">
                <h3>Create Your Online Shop</h3>
                <p>
                  Set up your store quickly with user-friendly tools that
                  simplify online selling.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content2">
                <h3>Choose a Store Theme</h3>
                <p>
                  Discover a variety of stylish premium themes to enhance your
                  store's look.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="we_provide_content we_provide_content3">
                <h3>Customize Your Brand Identity</h3>
                <p>
                  Personalize your store with easy design options that reflect
                  your unique brand style.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===We-Provide-end=== --> */}

      {/* <!-- ===About-Website-growth-start=== --> */}
      <section className="about_website_growth p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Launch, Grow, & Promote Your Products Online!</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_website_growth_card">
                <div className="about_website_growth_card_icon">
                  <svg
                    viewBox="0 0 50 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.625 21.25C30.625 21.7473 30.4275 22.2242 30.0758 22.5758C29.7242 22.9275 29.2473 23.125 28.75 23.125H21.25C20.7527 23.125 20.2758 22.9275 19.9242 22.5758C19.5725 22.2242 19.375 21.7473 19.375 21.25C19.375 20.7527 19.5725 20.2758 19.9242 19.9242C20.2758 19.5725 20.7527 19.375 21.25 19.375H28.75C29.2473 19.375 29.7242 19.5725 30.0758 19.9242C30.4275 20.2758 30.625 20.7527 30.625 21.25ZM49.375 11.875V41.875C49.375 42.8696 48.9799 43.8234 48.2766 44.5266C47.5734 45.2299 46.6196 45.625 45.625 45.625H4.375C3.38044 45.625 2.42661 45.2299 1.72335 44.5266C1.02009 43.8234 0.625 42.8696 0.625 41.875V11.875C0.625 10.8804 1.02009 9.92661 1.72335 9.22335C2.42661 8.52009 3.38044 8.125 4.375 8.125H13.75V6.25C13.75 4.75816 14.3426 3.32742 15.3975 2.27252C16.4524 1.21763 17.8832 0.625 19.375 0.625H30.625C32.1168 0.625 33.5476 1.21763 34.6025 2.27252C35.6574 3.32742 36.25 4.75816 36.25 6.25V8.125H45.625C46.6196 8.125 47.5734 8.52009 48.2766 9.22335C48.9799 9.92661 49.375 10.8804 49.375 11.875ZM17.5 8.125H32.5V6.25C32.5 5.75272 32.3025 5.27581 31.9508 4.92418C31.5992 4.57254 31.1223 4.375 30.625 4.375H19.375C18.8777 4.375 18.4008 4.57254 18.0492 4.92418C17.6975 5.27581 17.5 5.75272 17.5 6.25V8.125ZM45.625 21.6273V11.875H4.375V21.6273C10.7036 25.0721 17.7946 26.8763 25 26.875C32.2054 26.8763 39.2964 25.0721 45.625 21.6273Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="about_website_growth_card_content">
                  <h3>Boost Your Brand with an Online Store.</h3>
                  <p>
                    Everyone adores Launchestore, from beginners to established
                    companies. We are agency and technology partners to complete
                    any gaps and strengthen current teams. Building a website
                    and expanding your business is simple with our turnkey
                    eCommerce solution.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_website_growth_card">
                <div className="about_website_growth_card_icon">
                  <svg
                    viewBox="0 0 50 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.625 21.25C30.625 21.7473 30.4275 22.2242 30.0758 22.5758C29.7242 22.9275 29.2473 23.125 28.75 23.125H21.25C20.7527 23.125 20.2758 22.9275 19.9242 22.5758C19.5725 22.2242 19.375 21.7473 19.375 21.25C19.375 20.7527 19.5725 20.2758 19.9242 19.9242C20.2758 19.5725 20.7527 19.375 21.25 19.375H28.75C29.2473 19.375 29.7242 19.5725 30.0758 19.9242C30.4275 20.2758 30.625 20.7527 30.625 21.25ZM49.375 11.875V41.875C49.375 42.8696 48.9799 43.8234 48.2766 44.5266C47.5734 45.2299 46.6196 45.625 45.625 45.625H4.375C3.38044 45.625 2.42661 45.2299 1.72335 44.5266C1.02009 43.8234 0.625 42.8696 0.625 41.875V11.875C0.625 10.8804 1.02009 9.92661 1.72335 9.22335C2.42661 8.52009 3.38044 8.125 4.375 8.125H13.75V6.25C13.75 4.75816 14.3426 3.32742 15.3975 2.27252C16.4524 1.21763 17.8832 0.625 19.375 0.625H30.625C32.1168 0.625 33.5476 1.21763 34.6025 2.27252C35.6574 3.32742 36.25 4.75816 36.25 6.25V8.125H45.625C46.6196 8.125 47.5734 8.52009 48.2766 9.22335C48.9799 9.92661 49.375 10.8804 49.375 11.875ZM17.5 8.125H32.5V6.25C32.5 5.75272 32.3025 5.27581 31.9508 4.92418C31.5992 4.57254 31.1223 4.375 30.625 4.375H19.375C18.8777 4.375 18.4008 4.57254 18.0492 4.92418C17.6975 5.27581 17.5 5.75272 17.5 6.25V8.125ZM45.625 21.6273V11.875H4.375V21.6273C10.7036 25.0721 17.7946 26.8763 25 26.875C32.2054 26.8763 39.2964 25.0721 45.625 21.6273Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="about_website_growth_card_content">
                  <h3>Launch Your Online Store Today</h3>
                  <p>
                    Creating an ideal website for your company and starting to
                    sell products online is simple with Launchestore. Our fully
                    functional website builder, product and order management,
                    customer marketing tools, and other features are all part of
                    our complete eCommerce system.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_website_growth_card">
                <div className="about_website_growth_card_icon">
                  <svg
                    viewBox="0 0 50 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.625 21.25C30.625 21.7473 30.4275 22.2242 30.0758 22.5758C29.7242 22.9275 29.2473 23.125 28.75 23.125H21.25C20.7527 23.125 20.2758 22.9275 19.9242 22.5758C19.5725 22.2242 19.375 21.7473 19.375 21.25C19.375 20.7527 19.5725 20.2758 19.9242 19.9242C20.2758 19.5725 20.7527 19.375 21.25 19.375H28.75C29.2473 19.375 29.7242 19.5725 30.0758 19.9242C30.4275 20.2758 30.625 20.7527 30.625 21.25ZM49.375 11.875V41.875C49.375 42.8696 48.9799 43.8234 48.2766 44.5266C47.5734 45.2299 46.6196 45.625 45.625 45.625H4.375C3.38044 45.625 2.42661 45.2299 1.72335 44.5266C1.02009 43.8234 0.625 42.8696 0.625 41.875V11.875C0.625 10.8804 1.02009 9.92661 1.72335 9.22335C2.42661 8.52009 3.38044 8.125 4.375 8.125H13.75V6.25C13.75 4.75816 14.3426 3.32742 15.3975 2.27252C16.4524 1.21763 17.8832 0.625 19.375 0.625H30.625C32.1168 0.625 33.5476 1.21763 34.6025 2.27252C35.6574 3.32742 36.25 4.75816 36.25 6.25V8.125H45.625C46.6196 8.125 47.5734 8.52009 48.2766 9.22335C48.9799 9.92661 49.375 10.8804 49.375 11.875ZM17.5 8.125H32.5V6.25C32.5 5.75272 32.3025 5.27581 31.9508 4.92418C31.5992 4.57254 31.1223 4.375 30.625 4.375H19.375C18.8777 4.375 18.4008 4.57254 18.0492 4.92418C17.6975 5.27581 17.5 5.75272 17.5 6.25V8.125ZM45.625 21.6273V11.875H4.375V21.6273C10.7036 25.0721 17.7946 26.8763 25 26.875C32.2054 26.8763 39.2964 25.0721 45.625 21.6273Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="about_website_growth_card_content">
                  <h3>Start Selling Online Without Fees.</h3>
                  <p>
                    Our people will win your heart every time. They are crucial
                    to maintaining the highest levels of consumer satisfaction
                    in the market today. To dramatically increase your online
                    sales, try the correct marketing mix tailored for your
                    business. Start selling now; the time is here for you to
                    shine!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_website_growth_card">
                <div className="about_website_growth_card_icon">
                  <svg
                    viewBox="0 0 50 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.625 21.25C30.625 21.7473 30.4275 22.2242 30.0758 22.5758C29.7242 22.9275 29.2473 23.125 28.75 23.125H21.25C20.7527 23.125 20.2758 22.9275 19.9242 22.5758C19.5725 22.2242 19.375 21.7473 19.375 21.25C19.375 20.7527 19.5725 20.2758 19.9242 19.9242C20.2758 19.5725 20.7527 19.375 21.25 19.375H28.75C29.2473 19.375 29.7242 19.5725 30.0758 19.9242C30.4275 20.2758 30.625 20.7527 30.625 21.25ZM49.375 11.875V41.875C49.375 42.8696 48.9799 43.8234 48.2766 44.5266C47.5734 45.2299 46.6196 45.625 45.625 45.625H4.375C3.38044 45.625 2.42661 45.2299 1.72335 44.5266C1.02009 43.8234 0.625 42.8696 0.625 41.875V11.875C0.625 10.8804 1.02009 9.92661 1.72335 9.22335C2.42661 8.52009 3.38044 8.125 4.375 8.125H13.75V6.25C13.75 4.75816 14.3426 3.32742 15.3975 2.27252C16.4524 1.21763 17.8832 0.625 19.375 0.625H30.625C32.1168 0.625 33.5476 1.21763 34.6025 2.27252C35.6574 3.32742 36.25 4.75816 36.25 6.25V8.125H45.625C46.6196 8.125 47.5734 8.52009 48.2766 9.22335C48.9799 9.92661 49.375 10.8804 49.375 11.875ZM17.5 8.125H32.5V6.25C32.5 5.75272 32.3025 5.27581 31.9508 4.92418C31.5992 4.57254 31.1223 4.375 30.625 4.375H19.375C18.8777 4.375 18.4008 4.57254 18.0492 4.92418C17.6975 5.27581 17.5 5.75272 17.5 6.25V8.125ZM45.625 21.6273V11.875H4.375V21.6273C10.7036 25.0721 17.7946 26.8763 25 26.875C32.2054 26.8763 39.2964 25.0721 45.625 21.6273Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="about_website_growth_card_content">
                  <h3>Grow Your Business with Mobile Apps</h3>
                  <p>
                    The flexibility you require (or haven't considered yet). By
                    developing a mobile app, you may provide your company with
                    one more valuable asset while significantly boosting net
                    sales. Increase your market reach & customer engagement by
                    customizing your app to fit your brand's unique needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===About-Website-growth-end=== --> */}

      <WebsiteStep />
      <div className="marquee">
        <div className="marquee__row">
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
        </div>
        <div aria-hidden="true" className="marquee__row">
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
          <div className="marquee__item">
            <p className="marquee__text">Book a Free Demo</p>
          </div>
          <span className="marquee__separator">&#9679;</span>
        </div>
      </div>

      {/* <!-- ===growth-start=== --> */}
      <section className="website_growth p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="build_web_app-title">
                <h2>Launchstore versatile solution & Features</h2>
                <div>
                  <button
                    className="cmn-btn"
                    onClick={() => {
                      navigate("/features");
                    }}
                  >
                    <span className="cmn-btn-text1">
                      Browse Features{" "}
                      <MdOutlineArrowOutward className="button-icon" />
                    </span>
                    <span className="cmn-btn-text2">
                      Browse Features{" "}
                      <MdOutlineArrowOutward className="button-icon" />{" "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Zero IT cost</h3>
                  <p>
                    Join thousands of businesses trusting our eCommerce
                    platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Easy Store Management</h3>
                  <p>
                    Quickly manage products & speed up tasks with your
                    dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Integrated Logistics</h3>
                  <p>
                    Increase sales with our app no restrictions, friction, or
                    limits.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Integrated Payment Gateway</h3>
                  <p>
                    Quick, secure payments with our integrated payment system.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>SEO Friendly URL </h3>
                  <p>
                    Boost traffic with SEO-friendly URLs and editable robots.txt
                    files.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Multi Branches Platform</h3>
                  <p>
                    Manage multiple brands & regions easily from a single
                    account.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Launch Mobile Applications</h3>
                  <p>
                    Launch your new store and impress customers with
                    Launchestore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6">
              <div className="website_growth_card">
                <div className="website_growth_card_img">
                  <svg
                    viewBox="0 0 42 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.3125 33.375C41.3125 33.7894 41.1479 34.1868 40.8549 34.4799C40.5618 34.7729 40.1644 34.9375 39.75 34.9375H2.25C1.8356 34.9375 1.43817 34.7729 1.14515 34.4799C0.85212 34.1868 0.6875 33.7894 0.6875 33.375V2.125C0.6875 1.7106 0.85212 1.31317 1.14515 1.02015C1.43817 0.72712 1.8356 0.5625 2.25 0.5625C2.6644 0.5625 3.06183 0.72712 3.35485 1.02015C3.64788 1.31317 3.8125 1.7106 3.8125 2.125V23.3535L13.6445 13.5195C13.7896 13.3743 13.962 13.259 14.1517 13.1804C14.3413 13.1017 14.5447 13.0613 14.75 13.0613C14.9553 13.0613 15.1587 13.1017 15.3483 13.1804C15.538 13.259 15.7104 13.3743 15.8555 13.5195L21 18.666L31.291 8.375H27.25C26.8356 8.375 26.4382 8.21038 26.1451 7.91735C25.8521 7.62433 25.6875 7.2269 25.6875 6.8125C25.6875 6.3981 25.8521 6.00067 26.1451 5.70765C26.4382 5.41462 26.8356 5.25 27.25 5.25H35.0625C35.4769 5.25 35.8743 5.41462 36.1674 5.70765C36.4604 6.00067 36.625 6.3981 36.625 6.8125V14.625C36.625 15.0394 36.4604 15.4368 36.1674 15.7299C35.8743 16.0229 35.4769 16.1875 35.0625 16.1875C34.6481 16.1875 34.2507 16.0229 33.9576 15.7299C33.6646 15.4368 33.5 15.0394 33.5 14.625V10.584L22.1055 21.9805C21.9604 22.1257 21.788 22.241 21.5983 22.3196C21.4087 22.3983 21.2053 22.4387 21 22.4387C20.7947 22.4387 20.5913 22.3983 20.4017 22.3196C20.212 22.241 20.0396 22.1257 19.8945 21.9805L14.75 16.834L3.8125 27.7715V31.8125H39.75C40.1644 31.8125 40.5618 31.9771 40.8549 32.2701C41.1479 32.5632 41.3125 32.9606 41.3125 33.375Z"
                      fill="#977521"
                    />
                  </svg>
                </div>
                <div className="website_growth_card_content">
                  <h3>Your Own Brand Websites</h3>
                  <p>
                    Easily build a website with Launchestore to showcase your
                    brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ===growth-end=== --> */}

      <Testimonial />
      <LocationSlider />
    </>
  );
};

export default About;
